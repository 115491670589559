var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnInit } from '@angular/core';
import { GeneralService } from '../../../_services/general.service';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { ApiService } from '../../../_interceptors/api.service';
import { KVVMReportsService } from '../../../_services/kvvm-reports.service';
var KVVMReportsListComponent = /** @class */ (function () {
    function KVVMReportsListComponent(reportsService, apiService, previousUrlService, generalService) {
        this.reportsService = reportsService;
        this.apiService = apiService;
        this.previousUrlService = previousUrlService;
        this.generalService = generalService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.isLoading = false;
        this.keepLoading = false;
        this.reports = [];
        this.smallWidget = false;
    }
    KVVMReportsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && !_this.reports.length) {
                _this.currentPage = 0;
                _this.getReports(false);
            }
        });
        this.currentPage = 0;
        this.getReports(false);
    };
    KVVMReportsListComponent.prototype.getReports = function (append_data) {
        var _this = this;
        if (append_data === void 0) { append_data = true; }
        if (!this.isLoading || this.keepLoading) {
            this.isLoading = true;
            this.keepLoading = false;
            this.currentPage++;
            this.reportsService.getReports({
                start: (this.currentPage - 1) * this.generalService.itemsPerPage,
                length: this.generalService.itemsPerPage,
                'columns[0][search][value]': this.searchString
            }).then(function (data) {
                var _a;
                if (typeof data !== 'undefined') {
                    if (typeof data.recordsTotal !== 'undefined') {
                        _this.totalItems = data.recordsTotal;
                    }
                    if (append_data === true) {
                        (_a = _this.reports).push.apply(_a, __spread(data.data));
                    }
                    else {
                        _this.reports = data.data;
                    }
                }
                _this.isLoading = false;
            });
        }
    };
    KVVMReportsListComponent.prototype.search = function (event) {
        this.searchString = event;
        this.currentPage = 0;
        this.getReports(false);
    };
    return KVVMReportsListComponent;
}());
export { KVVMReportsListComponent };
