<form [formGroup]="myProfileForm" (ngSubmit)="authenticationService.updateMyProfile(myProfileForm.value)">
	<mat-form-field>
		<input matInput placeholder="Voornaam" type="text" required formControlName="firstname">
		<mat-error *ngIf="formsService.getFieldError('firstname')">{{formsService.getFieldError('firstname')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Achternaam" type="text" required formControlName="lastname">
		<mat-error *ngIf="formsService.getFieldError('lastname')">{{formsService.getFieldError('lastname')}}</mat-error>
	</mat-form-field>
	<button color="primary" mat-button [disabled]="!myProfileForm.valid">MIJN PROFIEL AANPASSEN</button>
</form>
