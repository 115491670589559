<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/buildings/' + building_id + '/reports']">Bekijk gebouwen schade</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/buildings']">Bekijk alle gebouwen</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">Bekijk rapportage</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<form class="with-action-buttons" [formGroup]="reportsBuildingsReportEditForm" (ngSubmit)="reportsService.updateBuildingReport(report_id, building_id, building_report_id, reportsBuildingsReportEditForm.value)">
		<div class="divider"><span>Gebouw rapportage gegevens</span></div>
		<div fxLayout="row">
			<mat-form-field>
				<input title="" matInput placeholder="Locatie nummer" type="text" required formControlName="location_nr">
				<mat-error *ngIf="formsService.getFieldError('location_nr')">{{formsService.getFieldError('location_nr')}}</mat-error>
			</mat-form-field>
		</div>
		<mat-form-field>
			<mat-select placeholder="Hoofd onderdeel" required formControlName="lib_warehouse_element_id" (selectionChange)="getSubcategories($event.value)">
				<mat-option *ngFor="let category of categories" [value]="category.id" (click)="setCategoryName(category.category_name)">{{ category.category_name }}</mat-option>
			</mat-select>
			<mat-error *ngIf="formsService.getFieldError('lib_warehouse_element_id')">{{formsService.getFieldError('lib_warehouse_element_id')}}</mat-error>
			<input type="hidden" formControlName="category_name">
		</mat-form-field>
		<mat-form-field>
			<mat-select placeholder="Subonderdeel" required formControlName="lib_warehouse_subelement_id" (selectionChange)="getDetails($event.value)">
				<mat-option *ngFor="let subcategory of subcategories" [value]="subcategory.id" (click)="setSubcategoryName(subcategory.subcategory_name)">{{ subcategory.subcategory_name }}</mat-option>
			</mat-select>
			<mat-error *ngIf="formsService.getFieldError('lib_warehouse_subelement_id')">{{formsService.getFieldError('lib_warehouse_subelement_id')}}</mat-error>
			<input type="hidden" formControlName="subcategory_name">
		</mat-form-field>
		<mat-radio-group formControlName="color_code" style="margin-bottom:20px;display:block;">
			<mat-radio-button color="primary" value="2">Groen</mat-radio-button>
			<mat-radio-button color="primary" value="0">Oranje</mat-radio-button>
			<mat-radio-button color="primary" value="1">Rood</mat-radio-button>
		</mat-radio-group>
		<div fxLayout="row">
			<mat-form-field>
				<input title="" matInput placeholder="Diepte" type="number" required formControlName="measure_depth">
				<mat-error *ngIf="formsService.getFieldError('measure_depth')">{{formsService.getFieldError('measure_depth')}}</mat-error>
			</mat-form-field>
			<button type="button" color="primary" mat-icon-button (click)="decreaseValue('measure_depth')">
				<mat-icon>remove</mat-icon>
			</button>
			<button type="button" color="primary" mat-icon-button (click)="increaseValue('measure_depth')">
				<mat-icon>add</mat-icon>
			</button>
		</div>
		<div fxLayout="row">
			<mat-form-field>
				<input title="" matInput placeholder="Hoogte" type="number" required formControlName="measure_height">
				<mat-error *ngIf="formsService.getFieldError('measure_height')">{{formsService.getFieldError('measure_height')}}</mat-error>
			</mat-form-field>
			<button type="button" color="primary" mat-icon-button (click)="decreaseValue('measure_height')">
				<mat-icon>remove</mat-icon>
			</button>
			<button type="button" color="primary" mat-icon-button (click)="increaseValue('measure_height')">
				<mat-icon>add</mat-icon>
			</button>
		</div>
		<mat-form-field>
			<textarea title="" matInput rows="6" placeholder="Uitleg" formControlName="description"></textarea>
			<mat-error *ngIf="formsService.getFieldError('description')">{{formsService.getFieldError('description')}}</mat-error>
		</mat-form-field>
		<ng-container *ngIf="types.length">
			<div class="divider"><span>Type tekortkomingen</span></div>
			<div *ngFor="let type of types; let i = index">
				<div formArrayName="warehouse_type_ids">
					<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ type.id }}" (change)="fixAngularScrewUpAndInsertActualValue('warehouse_type_ids', $event, i)">{{ type.type_name }}</mat-checkbox>
				</div>
				<div *ngIf="type.amounts === true" fxLayout="row">
					<mat-form-field formGroupName="warehouse_type_field_value">
						<input title="" matInput placeholder="Stuks" type="number" formControlName="{{ type.id }}">
					</mat-form-field>
					<button type="button" color="primary" mat-icon-button (click)="decreaseValue('warehouse_type_field_value.' + type.id, 'warehouse_type_ids.' + i)">
						<mat-icon>remove</mat-icon>
					</button>
					<button type="button" color="primary" mat-icon-button (click)="increaseValue('warehouse_type_field_value.' + type.id, 'warehouse_type_ids.' + i, type.id)">
						<mat-icon>add</mat-icon>
					</button>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="actions.length">
			<div class="divider"><span>Acties</span></div>
			<div *ngFor="let action of actions; let i = index" formArrayName="warehouse_action_ids">
				<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ action.id }}" (change)="fixAngularScrewUpAndInsertActualValue('warehouse_action_ids', $event, i)">{{ action.action_name }}</mat-checkbox>
			</div>
		</ng-container>
		<div class="divider"><span>Foto's</span></div>
		<mat-form-field class="photo-input">
			<ngx-mat-file-input formControlName="photosContainer[]" [multiple]="true" [accept]="'.jpg,.jpeg'" (change)="detectUploadFiles($event)" #photosContainer></ngx-mat-file-input>
			<button type="button" mat-button color="primary">
				<mat-icon>camera_alt</mat-icon>
				FOTO MAKEN
			</button>
			<mat-error *ngIf="formsService.getFieldError('photos')">{{formsService.getFieldError('photos')}}</mat-error>
		</mat-form-field>
		<mat-error *ngIf="photosCount >= 10">U kunt maximaal 10 foto's selecteren om te uploaden.</mat-error>
		<mat-list class="photos-container">
			<ng-template #photos></ng-template>
		</mat-list>
		<br>
		<ng-container *ngIf="buildingReport?.photos?.inspector">
			<div class="divider"><span>Ge-uploadde foto's</span></div>
			<div class="image-container">
				<div *ngFor="let photo of buildingReport.photos.inspector; let i = index">
					<ng-container *ngIf="photo.offline">
						<button type="button" mat-icon-button color="white" (click)="deleteBuildingReportPhoto('inspector', photo.id, i)">
							<mat-icon>delete</mat-icon>
						</button>
						<img src="{{ offlinePhoto[i] }}" alt="">
					</ng-container>
					<ng-container *ngIf="!photo.offline">
						<button type="button" mat-icon-button color="white" (click)="deleteBuildingReportPhoto('inspector', photo.id, i)">
							<mat-icon>delete</mat-icon>
						</button>
						<img src="{{ photo.filename_url }}" alt="">
					</ng-container>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="buildingReport?.photos?.by_client">
			<div class="divider"><span>Ge-uploadde foto's van klant</span></div>
			<div class="image-container">
				<div *ngFor="let photo of buildingReport.photos.by_client; let i = index">
					<ng-container *ngIf="photo.offline">
						<button type="button" mat-icon-button color="white" (click)="deleteBuildingReportPhoto('by_client', photo.id, i)">
							<mat-icon>delete</mat-icon>
						</button>
						<img src="{{ offlinePhoto[i] }}" alt="">
					</ng-container>
					<ng-container *ngIf="!photo.offline">
						<button type="button" mat-icon-button color="white" (click)="deleteBuildingReportPhoto('by_client', photo.id, i)">
							<mat-icon>delete</mat-icon>
						</button>
						<img src="{{ photo.filename_url }}" alt="">
					</ng-container>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="buildingReport.color_code !== 2 && buildingReport.color_code !== '2'">
			<div class="divider"><span>Afvinken</span></div>
			<ng-container *ngIf="buildingReport.is_resolved !== true && buildingReport.color_code !== 2 && buildingReport.color_code !== '2'">
				<mat-checkbox color="primary" formControlName="client_fixed_status" value="1" (change)="fixAngularScrewUpAndInsertActualValue2('client_fixed_status', $event)">Is dit punt opgelost?</mat-checkbox>
				<mat-form-field>
					<textarea title="" matInput rows="6" placeholder="Geef een toelichting hoe dit punt is opgelost" id="client_fixed_comment" formControlName="client_fixed_comment"></textarea>
				</mat-form-field>
				<div class="action-buttons">
					<button color="primary" mat-button [disabled]="!reportsBuildingsReportEditForm.valid">OPSLAAN</button>
				</div>
			</ng-container>
			<ng-container *ngIf="buildingReport.is_resolved === true">
				<div class="form-line">
					<div class="form-label">Is dit punt opgelost?</div>
					<div class="form-content">Ja</div>
				</div>
				<div class="form-line">
					<div class="form-label">Datum</div>
					<div class="form-content" *ngIf="buildingReport.client_fixed_date">{{ buildingReport.client_fixed_date | datex }}</div>
					<div class="form-content" *ngIf="!buildingReport.client_fixed_date">-</div>
				</div>
				<div class="form-line">
					<div class="form-label">Oplossen details</div>
					<div class="form-content" *ngIf="buildingReport.client_fixed_comment">{{ buildingReport.client_fixed_comment }}</div>
					<div class="form-content" *ngIf="!buildingReport.client_fixed_comment">-</div>
				</div>
			</ng-container>
		</ng-container>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!reportsBuildingsReportEditForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
