<button mat-button color="primary" [routerLink]="['/reports/create']">Nieuwe rapportage toevoegen</button>
<app-search-element *ngIf="smallWidget === false" (formSubmittedEvent)="search($event)"></app-search-element>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReports()">
	<mat-list-item *ngFor="let report of reports">
		<div class="mat-list-content" [routerLink]="['/reports/' + report.id + '/view']">
			<h4 matLine>{{ report.report_nr }}</h4>
			<a matLine>{{ report.created | datex:'DD-MM-YYYY' }}</a>
		</div>
		<ng-container *ngIf="report.color">
			<div class="tag" [style.background]="report.color"></div>
		</ng-container>
		<ng-container *ngIf="report.is_resolved">
			<mat-icon class="is_resolved">check_circle_outline</mat-icon>
		</ng-container>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="['/reports/' + report.id + '/edit']">Edit</a>
			<a mat-menu-item (click)="reportsService.copyReport(report.id)">Copy</a>
			<a mat-menu-item (click)="deleteReport(report.id)">Delete</a>
		</mat-menu>
	</mat-list-item>
</mat-nav-list>
