<mat-dialog-actions>
	<button mat-button type="button" color="primary-outline" (click)="toggleHelp()"><mat-icon>help</mat-icon></button>
	<div class="button-group">
		<button mat-button type="button" color="primary-outline" (click)="zoomOut()"><mat-icon>zoom_out</mat-icon></button>
		<button mat-button type="button" color="primary-outline" (click)="zoomFit()"><mat-icon>zoom_out_map</mat-icon></button>
		<button mat-button type="button" color="primary-outline" (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>
	</div>
	<div class="button-group">
		<button *ngIf="!readonly" mat-button type="button" color="primary-outline" (click)="undo()"><mat-icon>undo</mat-icon></button>
		<button mat-button type="button" color="primary-outline" (click)="close()"><mat-icon>close</mat-icon></button>
	</div>
</mat-dialog-actions>
<mat-dialog-content>
	<div class="building-map-placeholder">
		<div class="building-map-help" [class.show]="showHelp">
			<p>De plattegrond functies zijn als volgt:</p>
			<ul>
				<li>u kunt de plattegrond naar wens in- en uitzoomen</li>
				<li>je kunt de plattegrond verplaatsen</li>
				<li>om een markering toe te voegen, klikt of tikt u op uw scherm</li>
				<li>om een markering te verwijderen, klikt of tikt u op de bestaande markering</li>
			</ul>
		</div>
		<div class="building-map-placeholder-zoom" #mapZoom (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)" (mousewheel)="onMouoseWheelScroll($event)">
			<img alt="" src="{{  buildingMapUrl }}" #mapImage>
		</div>
	</div>
</mat-dialog-content>
<div #tooltip="matTooltip" matTooltip="{{ tooltipMessage }}"></div>
