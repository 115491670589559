import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormsService } from '../../../_services/forms.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../_pipes/angular2-material-datepicker-custom-date';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { ReportsService } from '../../../_services/reports.service';
import { ApiService } from '../../../_interceptors/api.service';
import { PageTitleService } from '../../../_services/page-title.service';
import { environment } from '../../../../environments/environment';
import { MultipleEmailsValidator } from '../../../_validators/multiple-emails';
var ɵ0 = APP_DATE_FORMATS;
var ReportsSendToEmailComponent = /** @class */ (function () {
    function ReportsSendToEmailComponent(reportsService, apiService, formsService, activatedRoute, formBuilder, previousUrlService, pageTitleService) {
        this.reportsService = reportsService;
        this.apiService = apiService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.report_id = null;
        this.report = null;
        this.dataLoaded = false;
        this.missingData = [];
        this.reportSendToClientForm = this.formBuilder.group({
            custom_contacts: ['', MultipleEmailsValidator()],
            report_format: ['pdf'],
            message: ['']
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
    }
    ReportsSendToEmailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && _this.missingData.length) {
                _this.missingData = [];
                _this.loadData();
            }
        });
        this.formsService.form = this.reportSendToClientForm;
        this._errorsSubscription = this.reportsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.report_id = parseInt(params['report_id'], 10);
            _this.previousUrlService.setPreviousUrlIfNull('/reports/' + _this.report_id + '/view');
            _this.loadData();
        });
    };
    ReportsSendToEmailComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
    };
    ReportsSendToEmailComponent.prototype.loadData = function () {
        var _this = this;
        this.reportsService.getReport(this.report_id).then(function (details) {
            if (typeof details !== 'undefined') {
                _this.report = details;
                _this.pageTitleService.setTitle(details.report_nr + ' | Verstuur naar klant | ' + environment.webappName);
                _this.pageTitleService.setPageTitle(details.report_nr);
                _this.dataLoaded = true;
            }
            else {
                _this.missingData.push('Rapportage');
            }
        });
    };
    return ReportsSendToEmailComponent;
}());
export { ReportsSendToEmailComponent };
export { ɵ0 };
