<ng-container *ngIf="publicClass && group">
	<button mat-button color="primary-outline" [routerLink]="[(publicClass ? '/public' : '') + '/knowledge-base/' + group.url]">
		<mat-icon>arrow_back</mat-icon>
		Back to articles
	</button>
</ng-container>
<ng-container *ngIf="group">
	<ng-container *ngIf="group.article">
		<h1>{{ group.article.title }}</h1>
		<ng-container *ngIf="group.article.video">
			<video preload="auto" controls="controls" playsInline="playsinline">
				<source src="{{ group.article.video }}?ngsw-bypass=true" type="video/mp4">
			</video>
		</ng-container>
		<div [innerHTML]="group.article.content" class="article-content"></div>
		<ng-container *ngIf="group.article.photos">
			<div class="image" *ngFor="let photo of group.article.photos">
				<img src="{{ photo }}" alt="">
			</div>
		</ng-container>
		<br>
		<button mat-button color="primary-outline" [routerLink]="[(publicClass ? '/public' : '') + '/knowledge-base/' + group.url]">
			<mat-icon>arrow_back</mat-icon>
			Back to articles
		</button>
	</ng-container>
</ng-container>
<ng-container *ngIf="!group">
	<p>An error has occurred while loading knowledge base article. Please try again or contact us.</p>
</ng-container>
