<app-search-element *ngIf="smallWidget === false" (formSubmittedEvent)="search($event)"></app-search-element>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReports()">
	<mat-list-item *ngFor="let report of reports">
		<div class="mat-list-content" [routerLink]="['/kvvm-reports/' + report.id + '/view']">
			<h4 matLine>{{ report.report_nr }}</h4>
			<a matLine>{{ report.created | datex:'DD-MM-YYYY' }}</a>
		</div>
		<ng-container *ngIf="report.color">
			<div class="tag" [style.background]="report.color"></div>
		</ng-container>
		<ng-container *ngIf="report.is_resolved">
			<mat-icon class="is_resolved">check_circle_outline</mat-icon>
		</ng-container>
		<button mat-icon-button color="white-no-border" [routerLink]="['/kvvm-reports/' + report.id + '/view']">
			<mat-icon>keyboard_arrow_right</mat-icon>
		</button>
	</mat-list-item>
</mat-nav-list>
