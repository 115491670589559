<button mat-button color="primary-outline" [routerLink]="['/reports']">Bekijk alle rapportages</button>
<form class="with-action-buttons" [formGroup]="reportsCreateForm" (ngSubmit)="reportsService.createReport(reportsCreateForm.value)">
	<div class="divider"><span>Rapportage gegevens</span></div>
	<mat-form-field>
		<input matInput title="" type="text" formControlName="report_nr" required placeholder="Naam inspectie">
		<mat-error *ngIf="formsService.getFieldError('report_nr')">{{formsService.getFieldError('report_nr')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<textarea matInput title="" formControlName="client_description" rows="8" placeholder="Opmerkingen"></textarea>
		<mat-error *ngIf="formsService.getFieldError('client_description')">{{formsService.getFieldError('client_description')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!reportsCreateForm.valid">OPSLAAN</button>
	</div>
</form>
