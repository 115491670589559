<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/kvvm-reports/' + report_id + '/buildings']">Bekijk alle gebouwen</button>
	<button mat-button color="primary-outline" [routerLink]="['/kvvm-reports/' + report_id + '/view']">Bekijk rapportage</button>
	<ng-container *ngIf="hasBuildingMap">
		<ng-container *ngIf="apiService.isOnline">
			<p>Er is een plattegrond beschikbaar. Vouw het hieronder uit om het en toegevoegde posities te bekijken.</p>
			<button mat-button color="primary-outline" (click)="showBuildingMap()">Plattegrond openen</button>
		</ng-container>
		<ng-container *ngIf="!apiService.isOnline">
			<p>Map is available, but you can access it only when the device is connected to the internet.</p>
		</ng-container>
	</ng-container>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReportsBuildingReports()">
	<mat-list-item *ngFor="let buildingReport of buildingReports">
		<div class="mat-list-content" [routerLink]="['/kvvm-reports/' + report_id + '/buildings/' + building_id + '/reports/' + buildingReport.id + '/view']">
			<h4 matLine>{{ buildingReport.location_nr }}</h4>
			<a matLine>{{ buildingReport.category_name }}; {{ buildingReport.subcategory_name }}</a>
		</div>
		<ng-container *ngIf="buildingReport.color">
			<div class="tag" [style.background]="buildingReport.color"></div>
		</ng-container>
		<ng-container *ngIf="buildingReport.client_fixed_status">
			<mat-icon class="is_resolved">check_circle_outline</mat-icon>
		</ng-container>
		<button mat-icon-button color="white-no-border" [routerLink]="['/kvvm-reports/' + report_id + '/buildings/' + building_id + '/reports/' + buildingReport.id + '/view']">
			<mat-icon>keyboard_arrow_right</mat-icon>
		</button>
	</mat-list-item>
</mat-nav-list>
