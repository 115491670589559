var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { GeneralService } from '../../../../_services/general.service';
import { PreviousUrlService } from '../../../../_services/previous-url.service';
import { ReportsService } from '../../../../_services/reports.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../_interceptors/api.service';
import { PageTitleService } from '../../../../_services/page-title.service';
import { environment } from '../../../../../environments/environment';
var ReportsBuildingsListComponent = /** @class */ (function () {
    function ReportsBuildingsListComponent(reportsService, apiService, previousUrlService, activatedRoute, generalService, pageTitleService) {
        this.reportsService = reportsService;
        this.apiService = apiService;
        this.previousUrlService = previousUrlService;
        this.activatedRoute = activatedRoute;
        this.generalService = generalService;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.isLoading = false;
        this.keepLoading = false;
        this.report_id = null;
        this.reportBuildings = [];
        this._activatedRouteSubscription = null;
        this.smallWidget = false;
    }
    ReportsBuildingsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && !_this.reportBuildings.length) {
                _this.currentPage = 0;
                _this.getReportBuildings(false);
            }
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.report_id = parseInt(params['report_id'], 10);
            if (_this.smallWidget === false) {
                _this.reportsService.getReport(_this.report_id).then(function (report) {
                    if (typeof report !== 'undefined') {
                        _this.pageTitleService.setTitle(report.report_nr + ' | Rapportage gebouwen | ' + environment.webappName);
                        _this.pageTitleService.setPageTitle(report.report_nr);
                    }
                });
            }
            _this.currentPage = 0;
            _this.getReportBuildings(false);
        });
    };
    ReportsBuildingsListComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
    };
    ReportsBuildingsListComponent.prototype.getReportBuildings = function (append_data) {
        var _this = this;
        if (append_data === void 0) { append_data = true; }
        if (!this.isLoading || this.keepLoading) {
            this.isLoading = true;
            this.keepLoading = false;
            this.currentPage++;
            this.reportsService.getBuildings(this.report_id, {
                start: (this.currentPage - 1) * (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
                length: (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
                'columns[0][search][value]': this.searchString
            }).then(function (data) {
                var _a;
                if (typeof data !== 'undefined') {
                    if (typeof data.recordsTotal !== 'undefined') {
                        _this.totalItems = data.recordsTotal;
                    }
                    if (append_data === true) {
                        (_a = _this.reportBuildings).push.apply(_a, __spread(data.data));
                    }
                    else {
                        _this.reportBuildings = data.data;
                    }
                }
                _this.isLoading = false;
            });
        }
    };
    ReportsBuildingsListComponent.prototype.deleteReportBuilding = function (report_id, building_id) {
        var _this = this;
        this.reportsService.deleteBuilding(report_id, building_id).then(function (status) {
            if (status === true) {
                var index = _this.reportBuildings.findIndex(function (data) { return data.id === building_id; });
                if (index !== -1) {
                    _this.reportBuildings.splice(index, 1);
                }
            }
        });
    };
    ReportsBuildingsListComponent.prototype.search = function ($event) {
        this.searchString = $event;
        this.currentPage = 0;
        this.getReportBuildings(false);
    };
    return ReportsBuildingsListComponent;
}());
export { ReportsBuildingsListComponent };
