<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/kvvm-reports/' + report_id + '/view']">Bekijk rapportage</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="reportAttachments.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getAttachments()"></div>
	<mat-list-item *ngFor="let attachment of reportAttachments" [class.no-bottom-border]="smallWidget === true">
		<div class="mat-list-content" (click)="reportsService.downloadFile(attachment.download_url, attachment.original_filename)">
			<h4 matLine>{{ attachment.title }}</h4>
			<a matLine>
				<mat-icon class="small">get_app</mat-icon>
				<span>{{ attachment.created | datex:'DD-MM-YYYY HH:mm' }}</span>
			</a>
		</div>
		<button mat-icon-button  (click)="reportsService.downloadFile(attachment.download_url, attachment.original_filename)">
			<mat-icon>get_app</mat-icon>
		</button>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/kvvm-reports/' + report_id + '/attachments']" class="mat-list-item-more">
		<span matLine>Bekijk meer ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="reportAttachments.length === 0">
	<br> Geen bijlages gevonden.
</div>
