var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { GeneralService } from '../../../../../_services/general.service';
import { PreviousUrlService } from '../../../../../_services/previous-url.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../../_interceptors/api.service';
import { PageTitleService } from '../../../../../_services/page-title.service';
import { environment } from '../../../../../../environments/environment';
import { KVVMReportsService } from '../../../../../_services/kvvm-reports.service';
var KVVMReportsBuildingsReportsListComponent = /** @class */ (function () {
    function KVVMReportsBuildingsReportsListComponent(reportsService, apiService, activatedRoute, previousUrlService, pageTitleService, generalService) {
        this.reportsService = reportsService;
        this.apiService = apiService;
        this.activatedRoute = activatedRoute;
        this.previousUrlService = previousUrlService;
        this.pageTitleService = pageTitleService;
        this.generalService = generalService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.isLoading = false;
        this.keepLoading = false;
        this.report_id = null;
        this.building_id = null;
        this.buildingReports = [];
        this.hasBuildingMap = false;
        this.buildingMapUrl = null;
        this.mapMarkers = [];
        this._activatedRouteSubscription = null;
        this.smallWidget = false;
    }
    KVVMReportsBuildingsReportsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && !_this.buildingReports.length) {
                _this.currentPage = 0;
                _this.getReportsBuildingReports(false);
            }
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.report_id = parseInt(params['report_id'], 10);
            _this.building_id = parseInt(params['building_id'], 10);
            _this.reportsService.getBuilding(_this.report_id, _this.building_id).then(function (data) {
                if (typeof data !== 'undefined') {
                    if (typeof data.building_map_id !== 'undefined') {
                        if (data.building_map_id !== null) {
                            _this.reportsService.getBuildingMap(data.building_map_id).then(function (buildingMap) {
                                if (typeof buildingMap !== 'undefined') {
                                    _this.hasBuildingMap = true;
                                    _this.buildingMapUrl = buildingMap.map_url;
                                }
                            });
                            _this.reportsService.getBuildingMapMarkers(_this.report_id, _this.building_id).then(function (markers) {
                                _this.mapMarkers = markers;
                            });
                        }
                    }
                    if (_this.smallWidget === false) {
                        _this.pageTitleService.setTitle(data.name + ' | Gebouwen | ' + environment.webappName);
                        _this.pageTitleService.setPageTitle(data.name);
                    }
                }
            });
            _this.currentPage = 0;
            _this.getReportsBuildingReports(false);
        });
    };
    KVVMReportsBuildingsReportsListComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
    };
    KVVMReportsBuildingsReportsListComponent.prototype.getReportsBuildingReports = function (append_data) {
        var _this = this;
        if (append_data === void 0) { append_data = true; }
        if (!this.isLoading || this.keepLoading) {
            this.isLoading = true;
            this.keepLoading = false;
            this.currentPage++;
            this.reportsService.getBuildingReports(this.report_id, this.building_id, {
                start: (this.currentPage - 1) * this.generalService.itemsPerPage,
                length: this.generalService.itemsPerPage,
                'columns[0][search][value]': this.searchString
            }).then(function (data) {
                var _a;
                if (typeof data !== 'undefined') {
                    if (typeof data.recordsTotal !== 'undefined') {
                        _this.totalItems = data.recordsTotal;
                    }
                    if (append_data === true) {
                        (_a = _this.buildingReports).push.apply(_a, __spread(data.data));
                    }
                    else {
                        _this.buildingReports = data.data;
                    }
                }
                _this.isLoading = false;
            });
        }
    };
    KVVMReportsBuildingsReportsListComponent.prototype.search = function ($event) {
        this.searchString = $event;
        this.currentPage = 0;
        this.getReportsBuildingReports(false);
    };
    KVVMReportsBuildingsReportsListComponent.prototype.showBuildingMap = function () {
        this.reportsService.showReportBuildingMap(this.buildingMapUrl, true, this.mapMarkers).then(function () {
        });
    };
    return KVVMReportsBuildingsReportsListComponent;
}());
export { KVVMReportsBuildingsReportsListComponent };
