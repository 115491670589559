/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./knowledge-base-router.component";
import * as i3 from "../../../_services/previous-url.service";
var styles_KnowledgeBaseRouterComponent = [];
var RenderType_KnowledgeBaseRouterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_KnowledgeBaseRouterComponent, data: {} });
export { RenderType_KnowledgeBaseRouterComponent as RenderType_KnowledgeBaseRouterComponent };
export function View_KnowledgeBaseRouterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.previousUrlService.navigated.next(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, { activateEvents: "activate" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_KnowledgeBaseRouterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-knowledge-base-router", [], null, null, null, View_KnowledgeBaseRouterComponent_0, RenderType_KnowledgeBaseRouterComponent)), i0.ɵdid(1, 49152, null, 0, i2.KnowledgeBaseRouterComponent, [i3.PreviousUrlService], null, null)], null, null); }
var KnowledgeBaseRouterComponentNgFactory = i0.ɵccf("app-knowledge-base-router", i2.KnowledgeBaseRouterComponent, View_KnowledgeBaseRouterComponent_Host_0, {}, {}, []);
export { KnowledgeBaseRouterComponentNgFactory as KnowledgeBaseRouterComponentNgFactory };
