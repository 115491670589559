var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { NgxIndexedDB } from 'ngx-indexed-db/dist/ngx-indexed-db';
import { ApiService } from '../_interceptors/api.service';
import { BehaviorSubject } from 'rxjs/Rx';
import { FileInput } from 'ngx-material-file-input';
import { Router } from '@angular/router';
import { PreviousUrlService } from './previous-url.service';
import { SnackbarService } from './snackbar.service';
import { AuthenticationService } from './authentication.service';
import { LoadingOverlayService } from './loading-overlay.service';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { dbVersion } from '../../environments/version';
var IndexedDBService = /** @class */ (function () {
    function IndexedDBService(apiService, loadingOverlayService, authenticationService, snackbarService, previousUrlService, router) {
        this.apiService = apiService;
        this.loadingOverlayService = loadingOverlayService;
        this.authenticationService = authenticationService;
        this.snackbarService = snackbarService;
        this.previousUrlService = previousUrlService;
        this.router = router;
        this.syncStart = 0;
        this.database = null;
        this.databaseReady = new BehaviorSubject(false);
        this.syncComplete = new BehaviorSubject(false);
        this.tableDataSync = 'data_sync';
        this.tableKvvmReports = 'kvvm_reports';
        this.tableKvvmReportsAttachments = 'kvvm_reports_attachments';
        this.tableKvvmReportsComments = 'kvvm_reports_comments';
        this.tableKvvmReportsBuildings = 'kvvm_reports_buildings';
        this.tableKvvmReportsBuildingsReports = 'kvvm_reports_buildings_reports';
        this.tableKvvmReportsBuildingsReportsSolved = 'kvvm_reports_buildings_reports_solved';
        this.tableReportsCategories = 'reports_categories';
        this.tableReportsSubcategories = 'reports_subcategories';
        this.tableReportsActions = 'reports_actions';
        this.tableReportsTypes = 'reports_types';
        this.tableReports = 'reports';
        this.tableReportsPush = 'reports_push';
        this.tableReportsAttachments = 'reports_attachments';
        this.tableReportsAttachmentsPush = 'reports_attachments_push';
        this.tableReportsComments = 'reports_comments';
        this.tableReportsCommentsPush = 'reports_comments_push';
        this.tableReportsBuildings = 'reports_buildings';
        this.tableReportsBuildingsPush = 'reports_buildings_push';
        this.tableReportsBuildingsReports = 'reports_buildings_reports';
        this.tableReportsBuildingsReportsPush = 'reports_buildings_reports_push';
        this.tableReportsBuildingsReportsPhotos = 'reports_buildings_reports_photos';
        this.tableReportsSendToClient = 'reports_send_to_client';
        this.tableSettings = 'settings';
        this.dbVersion = dbVersion;
        this.dbName = 'KVVM-Klant';
        this.debug = !environment.production;
        this.tryToCreateDatabase = 10;
        this.databaseCreationTries = 0;
        this._authenticationService = null;
        this.openIndexedDatabase();
    }
    IndexedDBService.prototype.openIndexedDatabase = function () {
        var _this = this;
        this.database = new NgxIndexedDB(this.dbName, this.dbVersion);
        this.database.openDatabase(this.dbVersion, function (evt) { return _this.updateDatabase(evt); })
            .then(function () {
            if (_this.validateDatabase()) {
                _this.databaseReady.next(true);
                _this.syncStart = new Date().getTime();
                _this.removeOldData();
                _this.syncAll();
                _this.registerToEvents(_this.apiService);
                setInterval(function () {
                    if (_this.apiService.isOnline) {
                        _this.syncAll();
                    }
                }, (60 * 60) * 1000);
            }
        }, function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        });
    };
    IndexedDBService.prototype.updateDatabase = function (evt) {
        var db = evt.target.result;
        if (db.objectStoreNames.contains(this.tableDataSync) === false) {
            this.createDataSyncTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableKvvmReports) === false) {
            this.createKvvmReportsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableKvvmReportsAttachments) === false) {
            this.createKvvmReportsAttachmentsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableKvvmReportsComments) === false) {
            this.createKvvmReportsCommentsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableKvvmReportsBuildings) === false) {
            this.createKvvmReportsBuildingsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableKvvmReportsBuildingsReports) === false) {
            this.createKvvmReportsBuildingsReportsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableKvvmReportsBuildingsReportsSolved) === false) {
            this.createKvvmReportsBuildingsReportsSolvedTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsCategories) === false) {
            this.createReportsCategoriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsSubcategories) === false) {
            this.createReportsSubcategoriesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsActions) === false) {
            this.createReportsActionsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsTypes) === false) {
            this.createReportsTypesTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReports) === false) {
            this.createReportsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsPush) === false) {
            this.createReportsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsAttachments) === false) {
            this.createReportsAttachmentsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsAttachmentsPush) === false) {
            this.createReportsAttachmentsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsComments) === false) {
            this.createReportsCommentsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsCommentsPush) === false) {
            this.createReportsCommentsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildings) === false) {
            this.createReportsBuildingsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsPush) === false) {
            this.createReportsBuildingsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsReports) === false) {
            this.createReportsBuildingsReportsTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsReportsPush) === false) {
            this.createReportsBuildingsReportsPushTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsBuildingsReportsPhotos) === false) {
            this.createReportsBuildingsReportsPhotosTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableReportsSendToClient) === false) {
            this.createReportsSendToClientTable(evt);
        }
        if (db.objectStoreNames.contains(this.tableSettings) === false) {
            this.createSettingsTable(evt);
        }
        return true;
    };
    IndexedDBService.prototype.validateDatabase = function () {
        var db = this.database.dbWrapper.db;
        if (this.databaseCreationTries > this.tryToCreateDatabase) {
            this.loadingOverlayService.show();
            this.snackbarService.error('A serious error occurred with the database. Please reload the app.');
            this.database.deleteDatabase(this.dbName);
            return false;
        }
        this.databaseCreationTries++;
        if (db.objectStoreNames.contains(this.tableDataSync) === false ||
            db.objectStoreNames.contains(this.tableKvvmReports) === false ||
            db.objectStoreNames.contains(this.tableKvvmReportsAttachments) === false ||
            db.objectStoreNames.contains(this.tableKvvmReportsComments) === false ||
            db.objectStoreNames.contains(this.tableKvvmReportsBuildings) === false ||
            db.objectStoreNames.contains(this.tableKvvmReportsBuildingsReports) === false ||
            db.objectStoreNames.contains(this.tableKvvmReportsBuildingsReportsSolved) === false ||
            db.objectStoreNames.contains(this.tableReportsCategories) === false ||
            db.objectStoreNames.contains(this.tableReportsSubcategories) === false ||
            db.objectStoreNames.contains(this.tableReportsActions) === false ||
            db.objectStoreNames.contains(this.tableReportsTypes) === false ||
            db.objectStoreNames.contains(this.tableReports) === false ||
            db.objectStoreNames.contains(this.tableReportsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsAttachments) === false ||
            db.objectStoreNames.contains(this.tableReportsAttachmentsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsComments) === false ||
            db.objectStoreNames.contains(this.tableReportsCommentsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildings) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsReports) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsReportsPush) === false ||
            db.objectStoreNames.contains(this.tableReportsBuildingsReportsPhotos) === false ||
            db.objectStoreNames.contains(this.tableReportsSendToClient) === false ||
            db.objectStoreNames.contains(this.tableSettings) === false) {
            console.log('There\'s an issue with the database, we\'ll try to repair it ...');
            this.database.deleteDatabase(this.dbName);
            this.openIndexedDatabase();
            return false;
        }
        return true;
    };
    IndexedDBService.prototype.registerToEvents = function (apiService) {
        var _this = this;
        apiService.connectionChanged.subscribe(function (online) {
            if (online) {
                _this.databaseReady.subscribe(function (event) {
                    if (event) {
                        if (_this.debug) {
                            console.log('user went online, push any and all changes to the server');
                        }
                        _this.apiService.offlineError.emit(false);
                        _this.syncStart = new Date().getTime();
                        _this.startUpdating();
                    }
                    else {
                        if (_this.debug) {
                            console.log('user went online but database is not yet ready');
                        }
                    }
                });
            }
        });
    };
    IndexedDBService.prototype.createDataSyncTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableDataSync, {
            keyPath: 'name',
            autoIncrement: false
        });
        object.createIndex('name', 'name', { unique: true });
    };
    IndexedDBService.prototype.createKvvmReportsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableKvvmReports, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
    };
    IndexedDBService.prototype.createKvvmReportsAttachmentsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsAttachments, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createKvvmReportsCommentsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsComments, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createKvvmReportsBuildingsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsBuildings, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createKvvmReportsBuildingsReportsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsBuildingsReports, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createKvvmReportsBuildingsReportsSolvedTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableKvvmReportsBuildingsReportsSolved, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createReportsCategoriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsCategories, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
    };
    IndexedDBService.prototype.createReportsSubcategoriesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsSubcategories, {
            keyPath: ['id', 'category_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id'], { unique: true });
    };
    IndexedDBService.prototype.createReportsActionsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsActions, {
            keyPath: ['id', 'category_id', 'subcategory_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], { unique: true });
    };
    IndexedDBService.prototype.createReportsTypesTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsTypes, {
            keyPath: ['id', 'category_id', 'subcategory_id'],
            autoIncrement: false
        });
        object.createIndex('entryId', ['id', 'category_id', 'subcategory_id'], { unique: true });
    };
    IndexedDBService.prototype.createReportsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReports, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
    };
    IndexedDBService.prototype.createReportsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
    };
    IndexedDBService.prototype.createReportsAttachmentsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsAttachments, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createReportsAttachmentsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsAttachmentsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
    };
    IndexedDBService.prototype.createReportsCommentsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsComments, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createReportsCommentsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsCommentsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
    };
    IndexedDBService.prototype.createReportsBuildingsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildings, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
    };
    IndexedDBService.prototype.createReportsBuildingsReportsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReports, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsReportsPushTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReportsPush, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('indexedDBMethod', 'indexedDBMethod', { unique: false });
    };
    IndexedDBService.prototype.createReportsBuildingsReportsPhotosTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsBuildingsReportsPhotos, {
            keyPath: 'id',
            autoIncrement: true
        });
        object.createIndex('id', 'id', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
        object.createIndex('building_report_id', 'building_report_id', { unique: false });
    };
    IndexedDBService.prototype.createReportsSendToClientTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableReportsSendToClient, {
            keyPath: 'entryId',
            autoIncrement: true
        });
        object.createIndex('entryId', 'entryId', { unique: true });
        object.createIndex('report_id', 'report_id', { unique: false });
    };
    IndexedDBService.prototype.createSettingsTable = function (evt) {
        var object = evt.currentTarget.result.createObjectStore(this.tableSettings, {
            keyPath: 'name',
            autoIncrement: false
        });
        object.createIndex('name', 'name', { unique: true });
        object.createIndex('value', 'value', { unique: true });
    };
    IndexedDBService.prototype.startUpdating = function () {
        var _this = this;
        if (this._authenticationService !== null) {
            this._authenticationService.unsubscribe();
        }
        this._authenticationService = this.authenticationService.isAuthenticated().subscribe(function (event) {
            if (event) {
                _this.loadingOverlayService.show();
                if (_this.debug) {
                    console.log((new Date().getTime() - _this.syncStart), 'Starting of pushing of data to server ...');
                }
                _this.snackbarService.info('Synchronisatie is gestart, dit een moment duren ...');
                return Promise.all([
                    _this.pushToServer_ReportsCreate().then(function () {
                        return Promise.all([
                            _this.pushToServer_ReportsUpdate().then(function () {
                                return _this.pushToServer_ReportsDelete().then(function () {
                                    return _this.pushToServer_KVVMReportsUpdates().then(function () { return true; });
                                });
                            })
                        ]).then(function () {
                            return Promise.all([
                                _this.pushToServer_ReportsAttachmentsCopy().then(function () {
                                    return _this.pushToServer_ReportsAttachmentsCreate().then(function () {
                                        return _this.pushToServer_ReportsAttachmentsUpdate().then(function () {
                                            return _this.pushToServer_ReportsAttachmentsDelete().then(function () { return true; });
                                        });
                                    });
                                }),
                                _this.pushToServer_ReportsCommentsCreate().then(function () {
                                    return _this.pushToServer_ReportsCommentsUpdate().then(function () {
                                        return _this.pushToServer_ReportsCommentsDelete().then(function () { return true; });
                                    });
                                }),
                                _this.pushToServer_ReportsBuildingsCreate().then(function () {
                                    return _this.pushToServer_ReportsBuildingsUpdate().then(function () {
                                        return _this.pushToServer_ReportsBuildingsDelete().then(function () {
                                            return _this.pushToServer_ReportsBuildingsReportsCreate().then(function () {
                                                return _this.pushToServer_ReportsBuildingsReportsUpdate().then(function () {
                                                    return _this.pushToServer_ReportsBuildingsReportsDelete().then(function () {
                                                        return _this.pushToServer_ReportsBuildingsReportsPhotosCreate().then(function () {
                                                            return _this.pushToServer_ReportsBuildingsReportsPhotosDelete().then(function () {
                                                                return _this.pushToServer_ReportsSendToClient().then(function () { return true; });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                })
                            ]);
                        }).then(function () {
                            return true;
                        }).catch(function (error) { return new Error(error); });
                    })
                ]).then(function () {
                    if (_this.debug) {
                        console.log((new Date().getTime() - _this.syncStart), 'pushToServer all done, starting sync ....');
                    }
                    _this.syncAll(true);
                    return true;
                }).catch(function (error) {
                    _this.loadingOverlayService.hide();
                    throw new Error(error);
                });
            }
        });
    };
    IndexedDBService.prototype.removeOldData = function () {
        var _this = this;
        this.databaseReady.subscribe(function (event) {
            if (event) {
                setTimeout(function () {
                    _this.database.getAllFast(_this.tableReports).then(function (reports) {
                        var filtered = [], filtered_attachments = [], filtered_buildings = [], filtered_building_reports = [], filtered_comments = [], remove_up_to_date = moment().subtract('1 year').unix();
                        if (typeof reports !== 'undefined') {
                            reports.forEach(function (report) {
                                if (report.created <= remove_up_to_date) {
                                    filtered.push(report.id);
                                }
                            });
                        }
                        if (filtered.length) {
                            _this.database.getAllFast(_this.tableReportsAttachments).then(function (attachments) {
                                if (typeof attachments !== 'undefined') {
                                    attachments.forEach(function (attachment) {
                                        if (filtered.indexOf(attachment.report_id) !== -1) {
                                            filtered_attachments.push(attachment.id);
                                        }
                                    });
                                    if (filtered_attachments.length) {
                                        _this.database.deleteBulk(_this.tableReportsAttachments, filtered_attachments).then(function () {
                                        });
                                    }
                                }
                            });
                            _this.database.getAllFast(_this.tableReportsBuildings).then(function (buildings) {
                                if (typeof buildings !== 'undefined') {
                                    buildings.forEach(function (building) {
                                        if (filtered.indexOf(building.report_id) !== -1) {
                                            filtered_buildings.push(building.id);
                                        }
                                    });
                                    if (filtered_buildings.length) {
                                        _this.database.deleteBulk(_this.tableReportsBuildings, filtered_buildings).then(function () {
                                        });
                                    }
                                }
                            });
                            _this.database.getAllFast(_this.tableReportsBuildingsReports).then(function (building_reports) {
                                if (typeof building_reports !== 'undefined') {
                                    building_reports.forEach(function (building_report) {
                                        if (filtered.indexOf(building_report.report_id) !== -1) {
                                            filtered_building_reports.push(building_report.id);
                                        }
                                    });
                                    if (filtered_building_reports.length) {
                                        _this.database.deleteBulk(_this.tableReportsBuildingsReports, filtered_building_reports).then(function () {
                                        });
                                    }
                                }
                            });
                            _this.database.getAllFast(_this.tableReportsComments).then(function (comments) {
                                if (typeof comments !== 'undefined') {
                                    comments.forEach(function (comment) {
                                        if (filtered.indexOf(comment.report_id) !== -1) {
                                            filtered_comments.push(comment.id);
                                        }
                                    });
                                    if (filtered_comments.length) {
                                        _this.database.deleteBulk(_this.tableReportsComments, filtered_comments).then(function () {
                                        });
                                    }
                                }
                            });
                            _this.database.deleteBulk(_this.tableReports, filtered).then(function () {
                            });
                        }
                    });
                    /* KVVM Reports */
                    _this.database.getAllFast(_this.tableKvvmReports).then(function (reports) {
                        var filtered = [], filtered_attachments = [], filtered_buildings = [], filtered_building_reports = [], filtered_comments = [], remove_up_to_date = moment().subtract('1 year').unix();
                        if (typeof reports !== 'undefined') {
                            reports.forEach(function (report) {
                                if (report.created <= remove_up_to_date) {
                                    filtered.push(report.id);
                                }
                            });
                        }
                        if (filtered.length) {
                            _this.database.getAllFast(_this.tableKvvmReportsAttachments).then(function (attachments) {
                                if (typeof attachments !== 'undefined') {
                                    attachments.forEach(function (attachment) {
                                        if (filtered.indexOf(attachment.report_id) !== -1) {
                                            filtered_attachments.push(attachment.id);
                                        }
                                    });
                                    if (filtered_attachments.length) {
                                        _this.database.deleteBulk(_this.tableKvvmReportsAttachments, filtered_attachments).then(function () {
                                        });
                                    }
                                }
                            });
                            _this.database.getAllFast(_this.tableKvvmReportsBuildings).then(function (buildings) {
                                if (typeof buildings !== 'undefined') {
                                    buildings.forEach(function (building) {
                                        if (filtered.indexOf(building.report_id) !== -1) {
                                            filtered_buildings.push(building.id);
                                        }
                                    });
                                    if (filtered_buildings.length) {
                                        _this.database.deleteBulk(_this.tableKvvmReportsBuildings, filtered_buildings).then(function () {
                                        });
                                    }
                                }
                            });
                            _this.database.getAllFast(_this.tableKvvmReportsBuildingsReports).then(function (building_reports) {
                                if (typeof building_reports !== 'undefined') {
                                    building_reports.forEach(function (building_report) {
                                        if (filtered.indexOf(building_report.report_id) !== -1) {
                                            filtered_building_reports.push(building_report.id);
                                        }
                                    });
                                    if (filtered_building_reports.length) {
                                        _this.database.deleteBulk(_this.tableKvvmReportsBuildingsReports, filtered_building_reports).then(function () {
                                        });
                                    }
                                }
                            });
                            _this.database.getAllFast(_this.tableKvvmReportsComments).then(function (comments) {
                                if (typeof comments !== 'undefined') {
                                    comments.forEach(function (comment) {
                                        if (filtered.indexOf(comment.report_id) !== -1) {
                                            filtered_comments.push(comment.id);
                                        }
                                    });
                                    if (filtered_comments.length) {
                                        _this.database.deleteBulk(_this.tableKvvmReportsComments, filtered_comments).then(function () {
                                        });
                                    }
                                }
                            });
                            _this.database.deleteBulk(_this.tableKvvmReports, filtered).then(function () {
                            });
                        }
                    });
                }, 2500);
            }
        });
    };
    IndexedDBService.prototype.syncAll = function (showMessage) {
        var _this = this;
        if (showMessage === void 0) { showMessage = false; }
        if (this._authenticationService !== null) {
            this._authenticationService.unsubscribe();
        }
        this._authenticationService = this.authenticationService.isAuthenticated().subscribe(function (event) {
            if (event) {
                if (_this.debug) {
                    console.log((new Date().getTime() - _this.syncStart), 'starting of syncing of data ...');
                }
                return Promise.all([
                    _this.syncDeletedItems().then(function () {
                        return Promise.all([
                            _this.syncCategories(),
                            _this.syncSubcategories(),
                            _this.syncTypes(),
                            _this.syncActions(),
                            _this.syncReports(),
                            _this.syncReportsAttachments(),
                            _this.syncReportsComments(),
                            _this.syncReportsBuildings(),
                            _this.syncReportsBuildingsReports(),
                            _this.syncKVVMReports(),
                            _this.syncKVVMReportsAttachments(),
                            _this.syncKVVMReportsComments(),
                            _this.syncKVVMReportsBuildings(),
                            _this.syncKVVMReportsBuildingsReports(),
                            _this.syncSettings(),
                        ]);
                    })
                ]).then(function () {
                    if (_this.debug) {
                        console.log((new Date().getTime() - _this.syncStart), 'sync done');
                    }
                    _this.loadingOverlayService.hide();
                    if (showMessage) {
                        _this.snackbarService.info('Synchronisatie voltooid!');
                    }
                    _this.syncComplete.next(true);
                }).catch(function () {
                    _this.loadingOverlayService.hide();
                });
            }
        });
    };
    IndexedDBService.prototype.syncSettings = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_1 = [];
                _this.apiService.get('settings').then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    var start_1 = new Date().getTime(), settings_1 = [];
                                    Object.keys(data.data).map(function (key) {
                                        settings_1.push({
                                            name: key,
                                            value: data.data[key]
                                        });
                                    });
                                    promises_1.push(_this.database.updateBulk(_this.tableSettings, settings_1)
                                        .then(function () { return true; })
                                        .catch(function (error) { return new Error(error); }));
                                    return Promise.all(promises_1).then(function () {
                                        if (_this.debug) {
                                            console.log((new Date().getTime() - start_1), 'syncSettings done');
                                        }
                                        resolve();
                                    });
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }
                    else {
                        resolve();
                    }
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncDeletedItems = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_2 = [];
                _this.database.getByKey(_this.tableDataSync, 'deleted_items').then(function (user_device_date) {
                    _this.apiService.get('deleted-items/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('deleted-items', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_2 = new Date().getTime(), reports_1 = [], reports_attachments_1 = [], reports_buildings_1 = [], reports_buildings_reports_1 = [], reports_buildings_reports_photos_1 = [], categories_1 = [], subcategories_1 = [], types_1 = [], actions_1 = [], category_subcategory_type_1 = [], category_subcategory_action_1 = [];
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_2), 'delete: started ...');
                                                                }
                                                                data.data.forEach(function (data2) {
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid === null &&
                                                                        data2.rbrid === null &&
                                                                        data2.rbrpid === null) {
                                                                        reports_1.push({ rid: data2.rid });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid !== null &&
                                                                        data2.rbid === null &&
                                                                        data2.rbrid === null &&
                                                                        data2.rbrpid === null) {
                                                                        reports_attachments_1.push({ rid: data2.rid, raid: data2.raid });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid !== null &&
                                                                        data2.rbrid === null &&
                                                                        data2.rbrpid === null) {
                                                                        reports_buildings_1.push({ rid: data2.rid, rbid: data2.rbid });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid !== null &&
                                                                        data2.rbrid !== null &&
                                                                        data2.rbrpid === null) {
                                                                        reports_buildings_reports_1.push({
                                                                            rid: data2.rid,
                                                                            rbid: data2.rbid,
                                                                            rbrid: data2.rbrid
                                                                        });
                                                                    }
                                                                    if (data2.rid !== null &&
                                                                        data2.raid === null &&
                                                                        data2.rbid !== null &&
                                                                        data2.rbrid !== null &&
                                                                        data2.rbrpid !== null) {
                                                                        reports_buildings_reports_photos_1.push({
                                                                            rid: data2.rid,
                                                                            rbid: data2.rbid,
                                                                            rbrid: data2.rbrid,
                                                                            rbrpid: data2.rbrpid
                                                                        });
                                                                    }
                                                                    if (data2.wcid === null &&
                                                                        data2.wsid === null &&
                                                                        data2.waid !== null) {
                                                                        actions_1.push({ waid: data2.waid });
                                                                    }
                                                                    if (data2.wcid !== null &&
                                                                        data2.wsid === null &&
                                                                        data2.waid === null &&
                                                                        data2.wtid === null) {
                                                                        categories_1.push({ wcid: data2.wcid });
                                                                    }
                                                                    if (data2.wcid !== null &&
                                                                        data2.wsid !== null &&
                                                                        data2.waid === null &&
                                                                        data2.wtid === null) {
                                                                        subcategories_1.push({ wcid: data2.wcid, wsid: data2.wsid });
                                                                    }
                                                                    if (data2.wcid !== null &&
                                                                        data2.wsid !== null &&
                                                                        data2.waid !== null &&
                                                                        data2.wtid === null) {
                                                                        category_subcategory_action_1.push({
                                                                            wcid: data2.wcid,
                                                                            wsid: data2.wsid,
                                                                            waid: data2.waid
                                                                        });
                                                                    }
                                                                    if (data2.wcid !== null &&
                                                                        data2.wsid !== null &&
                                                                        data2.waid === null &&
                                                                        data2.wtid !== null) {
                                                                        category_subcategory_type_1.push({
                                                                            wcid: data2.wcid,
                                                                            wsid: data2.wsid,
                                                                            wtid: data2.wtid
                                                                        });
                                                                    }
                                                                    if (data2.wcid === null &&
                                                                        data2.wsid === null &&
                                                                        data2.wtid !== null) {
                                                                        types_1.push({ wtid: data2.wtid });
                                                                    }
                                                                });
                                                                if (reports_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReport(null, reports_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_attachments_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_attachments start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportAttachment(null, reports_attachments_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_attachments done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_buildings_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_buildings start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportBuilding(null, reports_buildings_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_buildings done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_buildings_reports_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_buildings_reports start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportBuildingReport(reports_buildings_reports_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_buildings_reports done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (reports_buildings_reports_photos_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: reports_buildings_reports_photos start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteReportBuildingReportPhoto(null, reports_buildings_reports_photos_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: reports_buildings_reports_photos done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (categories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: categories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteCategory(categories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: categories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (subcategories_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: subcategories start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteSubcategory(null, subcategories_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: subcategories done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (types_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: types start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteType(types_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: types done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (actions_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: actions start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteAction(actions_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: actions done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (category_subcategory_type_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: category_subcategory_type start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteCategorySubcategoryType(category_subcategory_type_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: category_subcategory_type done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                if (category_subcategory_action_1.length) {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: category_subcategory_action start');
                                                                    }
                                                                    promises_2.push(new Promise(function (resolve2) {
                                                                        _this.deleteCategorySubcategoryAction(category_subcategory_action_1)
                                                                            .then(function () {
                                                                            if (_this.debug) {
                                                                                console.log((new Date().getTime() - start_2), 'delete: category_subcategory_action done');
                                                                            }
                                                                            resolve2();
                                                                        });
                                                                    }));
                                                                }
                                                                promises_2.push(new Promise(function (resolve2) {
                                                                    _this.database.update(_this.tableDataSync, {
                                                                        name: 'deleted_items',
                                                                        updated: server_date.data.updated
                                                                    })
                                                                        .then(function () {
                                                                        resolve2();
                                                                    })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }));
                                                                return Promise.all(promises_2).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_2), 'delete: finished');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncCategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_3 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsCategories).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/elements/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync/elements', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_3 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_3), 'syncCategories start ...');
                                                                }
                                                                promises_3.push(_this.database.clear(_this.tableReportsCategories).then(function () {
                                                                    return _this.database.updateBulk(_this.tableReportsCategories, data.data)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }));
                                                                promises_3.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsCategories,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_3).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_3), 'syncCategories done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncSubcategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_4 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsSubcategories).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/elements/subelements/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync/elements/subelements', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_4 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_4), 'syncSubcategories start ...');
                                                                }
                                                                promises_4.push(_this.database.clear(_this.tableReportsSubcategories).then(function () {
                                                                    return _this.database.updateBulk(_this.tableReportsSubcategories, data.data)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }));
                                                                promises_4.push(_this.database.getAllFast(_this.tableReportsTypes)
                                                                    .then(function (types) {
                                                                    if (typeof types !== 'undefined') {
                                                                        if (types.length) {
                                                                            var filtered_1 = [];
                                                                            types.forEach(function (type) {
                                                                                data.data.forEach(function (server_type) {
                                                                                    if (type.category_id === server_type.category_id && type.subcategory_id === server_type.id) {
                                                                                        type.amounts = server_type.amounts;
                                                                                        filtered_1.push(type);
                                                                                    }
                                                                                });
                                                                            });
                                                                            if (filtered_1.length) {
                                                                                return _this.database.updateBulk(_this.tableReportsTypes, filtered_1)
                                                                                    .then(function () { return true; })
                                                                                    .catch(function (error) { return new Error(error); });
                                                                            }
                                                                        }
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_4.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsSubcategories,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_4).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_4), 'syncSubcategories done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncTypes = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_5 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsTypes).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/elements/subelements/types/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync/elements/subelements/types', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_5 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_5), 'syncTypes start ...');
                                                                }
                                                                var filtered_2 = [];
                                                                data.data.forEach(function (type) {
                                                                    type.type_name = type.type_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                                    filtered_2.push(type);
                                                                });
                                                                if (filtered_2.length) {
                                                                    promises_5.push(_this.database.updateBulk(_this.tableReportsTypes, filtered_2)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); }));
                                                                }
                                                                promises_5.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsTypes,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_5).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_5), 'syncTypes done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncActions = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_6 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsActions).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/elements/subelements/actions/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync/elements/subelements/actions', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_6 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_6), 'syncActions start ...');
                                                                }
                                                                var filtered_3 = [];
                                                                data.data.forEach(function (action) {
                                                                    action.action_name = action.action_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                                    filtered_3.push(action);
                                                                });
                                                                if (filtered_3.length) {
                                                                    promises_6.push(_this.database.updateBulk(_this.tableReportsActions, filtered_3)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); }));
                                                                }
                                                                promises_6.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsActions,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_6).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_6), 'syncActions done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_7 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReports).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_7 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_7), 'syncReports start ...');
                                                                }
                                                                promises_7.push(_this.database.updateBulk(_this.tableReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_7.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReports,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_7).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_7), 'syncReports done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsAttachments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_8 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsAttachments).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/attachments/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync/attachments', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_8 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_8), 'syncReportsAttachments start ...');
                                                                }
                                                                promises_8.push(_this.database.updateBulk(_this.tableReportsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_8.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsAttachments,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_8).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_8), 'syncReportsAttachments done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsComments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_9 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsComments).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/comments/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync/comments', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_9 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_9), 'syncReportsComments start ...');
                                                                }
                                                                promises_9.push(_this.database.updateBulk(_this.tableReportsComments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_9.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsComments,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_9).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_9), 'syncReportsComments done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsBuildings = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_10 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsBuildings).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/buildings/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync/buildings', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_10 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_10), 'syncReportsBuildings start ...');
                                                                }
                                                                promises_10.push(_this.database.updateBulk(_this.tableReportsBuildings, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_10.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsBuildings,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_10).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_10), 'syncReportsBuildings done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncReportsBuildingsReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_11 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableReportsBuildingsReports).then(function (user_device_date) {
                    _this.apiService.get('reports/sync/buildings/reports/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('reports/sync/buildings/reports', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_11 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_11), 'syncReportsBuildingsReports start ...');
                                                                }
                                                                promises_11.push(_this.database.updateBulk(_this.tableReportsBuildingsReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_11.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableReportsBuildingsReports,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_11).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_11), 'syncReportsBuildingsReports done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsPush).then(function (reports) {
                if (typeof reports !== 'undefined') {
                    if (reports.length) {
                        reports.forEach(function (report) {
                            if (report.indexedDBMethod === '1-create') {
                                collectionData.push(report);
                            }
                        });
                        if (collectionData.length) {
                            collectionData.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports', report, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            var promises2 = [];
                                                            promises2.push(_this.database.delete(_this.tableReportsPush, report.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                            if (data.data.id !== report.id) {
                                                                var newId_1 = parseInt(data.data.id, 10);
                                                                promises2.push(_this.database.delete(_this.tableReports, report.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.add(_this.tableReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsPush)
                                                                    .then(function (reports2) {
                                                                    var filtered = [];
                                                                    reports2.forEach(function (report2) {
                                                                        if (report2.id === report.id) {
                                                                            report2.id = newId_1;
                                                                            filtered.push(report2);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsAttachments)
                                                                    .then(function (report_attachments) {
                                                                    var filtered = [];
                                                                    report_attachments.forEach(function (report_attachment) {
                                                                        if (report_attachment.report_id === report.id) {
                                                                            report_attachment.report_id = newId_1;
                                                                            filtered.push(report_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsAttachments, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                                                                    .then(function (report_attachments) {
                                                                    var filtered = [];
                                                                    report_attachments.forEach(function (report_attachment) {
                                                                        if (report_attachment.report_id === report.id) {
                                                                            report_attachment.report_id = newId_1;
                                                                            filtered.push(report_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsAttachmentsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsComments)
                                                                    .then(function (report_comments) {
                                                                    var filtered = [];
                                                                    report_comments.forEach(function (report_comment) {
                                                                        if (report_comment.report_id === report.id) {
                                                                            report_comment.report_id = newId_1;
                                                                            filtered.push(report_comment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsComments, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsCommentsPush)
                                                                    .then(function (report_comments) {
                                                                    var filtered = [];
                                                                    report_comments.forEach(function (report_comment) {
                                                                        if (report_comment.report_id === report.id) {
                                                                            report_comment.report_id = newId_1;
                                                                            filtered.push(report_comment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsCommentsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildings)
                                                                    .then(function (report_buildings) {
                                                                    var filtered = [];
                                                                    report_buildings.forEach(function (report_building) {
                                                                        if (report_building.report_id === report.id) {
                                                                            report_building.report_id = newId_1;
                                                                            filtered.push(report_building);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildings, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsPush)
                                                                    .then(function (report_buildings) {
                                                                    var filtered = [];
                                                                    report_buildings.forEach(function (report_building) {
                                                                        if (report_building.report_id === report.id) {
                                                                            report_building.report_id = newId_1;
                                                                            filtered.push(report_building);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildingsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                                                                    .then(function (report_buildings_reports) {
                                                                    var filtered = [];
                                                                    report_buildings_reports.forEach(function (report_building_report) {
                                                                        if (report_building_report.report_id === report.id) {
                                                                            report_building_report.report_id = newId_1;
                                                                            filtered.push(report_building_report);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildingsReports, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                                                                    .then(function (report_buildings_reports) {
                                                                    var filtered = [];
                                                                    report_buildings_reports.forEach(function (report_building_report) {
                                                                        if (report_building_report.report_id === report.id) {
                                                                            report_building_report.report_id = newId_1;
                                                                            filtered.push(report_building_report);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildingsReportsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos)
                                                                    .then(function (report_buildings_reports_photos) {
                                                                    var filtered = [];
                                                                    report_buildings_reports_photos.forEach(function (report_building_report_photo) {
                                                                        if (report_building_report_photo.report_id === report.id) {
                                                                            report_building_report_photo.report_id = newId_1;
                                                                            filtered.push(report_building_report_photo);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsBuildingsReportsPhotos, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsSendToClient)
                                                                    .then(function (reports_send_to_client) {
                                                                    var filtered = [];
                                                                    reports_send_to_client.forEach(function (report_send_to_client) {
                                                                        if (report_send_to_client.report_id === report.id) {
                                                                            report_send_to_client.report_id = newId_1;
                                                                            filtered.push(report_send_to_client);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsSendToClient, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                            }
                                                            else {
                                                                promises2.push(_this.database.update(_this.tableReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                            }
                                                            return Promise.all(promises2).then(function () {
                                                                _this.redirectUser({
                                                                    report_id: data.data.id,
                                                                    old_report_id: report.id
                                                                });
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsPush).then(function (reports) {
                if (typeof reports !== 'undefined') {
                    if (reports.length) {
                        reports.forEach(function (report) {
                            if (report.indexedDBMethod === '2-update') {
                                collectionData.push(report);
                            }
                        });
                        if (collectionData.length) {
                            collectionData.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + report.id, report, false).then(function (data) {
                                            if (data.success === true) {
                                                var promises2 = [];
                                                promises2.push(_this.database.update(_this.tableReports, data.data)
                                                    .then(function () { return true; })
                                                    .catch(function (error) { return new Error(error); }));
                                                promises2.push(_this.database.delete(_this.tableReportsPush, report.entryId)
                                                    .then(function () { return true; })
                                                    .catch(function (error) { return new Error(error); }));
                                                return Promise.all(promises2).then(function () {
                                                    resolve2();
                                                });
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsPush).then(function (reports) {
                if (typeof reports !== 'undefined') {
                    if (reports.length) {
                        reports.forEach(function (report) {
                            if (report.indexedDBMethod === '3-delete') {
                                collectionData.push(report);
                            }
                        });
                        if (collectionData.length) {
                            collectionData.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + report.id, false).then(function (data) {
                                            if (data.success === true) {
                                                var promises2 = [];
                                                promises2.push(_this.database.delete(_this.tableReports, report.id)
                                                    .then(function () { return true; })
                                                    .catch(function (error) { return new Error(error); }));
                                                promises2.push(_this.database.delete(_this.tableReportsPush, report.entryId)
                                                    .then(function () { return true; })
                                                    .catch(function (error) { return new Error(error); }));
                                                return Promise.all(promises2).then(function () {
                                                    resolve2();
                                                });
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsAttachmentsCopy = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var reportsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy start ...');
            }
            return _this.database.getAllFast(_this.tableReportsAttachmentsPush).then(function (reports_attachments) {
                if (typeof reports_attachments !== 'undefined') {
                    if (reports_attachments.length) {
                        reports_attachments.forEach(function (report_attachment) {
                            if (report_attachment.indexedDBMethod === '0-copy') {
                                reportsAttachments.push(report_attachment);
                            }
                        });
                        if (reportsAttachments.length) {
                            reportsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.get('reports/' + attachment.copy_from_report_id + '/attachments/' + attachment.copy_from_attachment_id + '/copy/' + attachment.report_id, null, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsAttachmentsPush, attachment.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        promises2.push(_this.database.delete(_this.tableReportsAttachments, attachment.id)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        promises2.push(_this.database.add(_this.tableReportsAttachments, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        var newId_2 = parseInt(data.data.id, 10);
                                                        promises2.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                                                            .then(function (reports_attachments2) {
                                                            var filtered = [];
                                                            reports_attachments2.forEach(function (report_attachment) {
                                                                if (report_attachment.id === attachment.id) {
                                                                    report_attachment.id = newId_2;
                                                                    filtered.push(report_attachment);
                                                                }
                                                            });
                                                            if (filtered.length) {
                                                                return _this.database.updateBulk(_this.tableReportsAttachmentsPush, filtered)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); });
                                                            }
                                                            return true;
                                                        })
                                                            .catch(function (error) { return new Error(error); }));
                                                        return Promise.all(promises2).then(function () {
                                                            _this.redirectUser({
                                                                report_id: data.data.report_id,
                                                                attachment_id: data.data.id
                                                            });
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCopy nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsAttachmentsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var reportsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsAttachmentsPush).then(function (reports_attachments) {
                if (typeof reports_attachments !== 'undefined') {
                    if (reports_attachments.length) {
                        reports_attachments.forEach(function (report_attachment) {
                            if (report_attachment.indexedDBMethod === '1-create') {
                                reportsAttachments.push(report_attachment);
                            }
                        });
                        if (reportsAttachments.length) {
                            reportsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var newAttachment = {
                                            filename: new FileInput(attachment.filename['_files']),
                                            title: attachment.title,
                                            description: attachment.description
                                        };
                                        _this.apiService.post('reports/' + attachment.report_id + '/attachments', newAttachment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        if (typeof data.data.id !== 'undefined') {
                                                            var promises2 = [];
                                                            promises2.push(_this.database.delete(_this.tableReportsAttachmentsPush, attachment.entryId)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                            if (data.data.id !== attachment.id) {
                                                                promises2.push(_this.database.delete(_this.tableReportsAttachments, attachment.id)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises2.push(_this.database.add(_this.tableReportsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                var newId_3 = parseInt(data.data.id, 10);
                                                                promises2.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                                                                    .then(function (reports_attachments2) {
                                                                    var filtered = [];
                                                                    reports_attachments2.forEach(function (report_attachment) {
                                                                        if (report_attachment.id === attachment.id) {
                                                                            report_attachment.id = newId_3;
                                                                            filtered.push(report_attachment);
                                                                        }
                                                                    });
                                                                    if (filtered.length) {
                                                                        return _this.database.updateBulk(_this.tableReportsAttachmentsPush, filtered)
                                                                            .then(function () { return true; })
                                                                            .catch(function (error) { return new Error(error); });
                                                                    }
                                                                    return true;
                                                                })
                                                                    .catch(function (error) { return new Error(error); }));
                                                            }
                                                            else {
                                                                promises2.push(_this.database.update(_this.tableReportsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                            }
                                                            return Promise.all(promises2).then(function () {
                                                                _this.redirectUser({
                                                                    report_id: data.data.report_id,
                                                                    attachment_id: data.data.id
                                                                });
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsAttachmentsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var reportsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsAttachmentsPush).then(function (reports_attachments) {
                if (typeof reports_attachments !== 'undefined') {
                    if (reports_attachments.length) {
                        reports_attachments.forEach(function (report_attachment) {
                            if (report_attachment.indexedDBMethod === '2-update') {
                                reportsAttachments.push(report_attachment);
                            }
                        });
                        if (reportsAttachments.length) {
                            reportsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + attachment.report_id + '/attachments/' + attachment.id, attachment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.update(_this.tableReportsAttachments, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        promises2.push(_this.database.delete(_this.tableReportsAttachmentsPush, attachment.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsAttachmentsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var reportsAttachments = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsAttachmentsPush).then(function (reports_attachments) {
                if (typeof reports_attachments !== 'undefined') {
                    if (reports_attachments.length) {
                        reports_attachments.forEach(function (report_attachment) {
                            if (report_attachment.indexedDBMethod === '3-delete') {
                                reportsAttachments.push(report_attachment);
                            }
                        });
                        if (reportsAttachments.length) {
                            reportsAttachments.forEach(function (attachment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + attachment.report_id + '/attachments/' + attachment.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsAttachments, attachment.id)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            promises2.push(_this.database.delete(_this.tableReportsAttachmentsPush, attachment.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsAttachmentsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsCommentsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var commentsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsCommentsPush).then(function (reports_comments) {
                if (typeof reports_comments !== 'undefined') {
                    if (reports_comments.length) {
                        reports_comments.forEach(function (report_comment) {
                            if (report_comment.indexedDBMethod === '1-create') {
                                commentsData.push(report_comment);
                            }
                        });
                        if (commentsData.length) {
                            commentsData.forEach(function (comment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + comment.report_id + '/comments', comment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsCommentsPush, comment.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        if (data.data.id !== comment['id']) {
                                                            promises2.push(_this.database.delete(_this.tableReportsComments, comment.id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.add(_this.tableReportsComments, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                            var newId_4 = parseInt(data.data.id, 10);
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsCommentsPush)
                                                                .then(function (reports_comments2) {
                                                                var filtered = [];
                                                                reports_comments2.forEach(function (report_comment) {
                                                                    if (report_comment.id === comment.id) {
                                                                        report_comment.id = newId_4;
                                                                        filtered.push(report_comment);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsCommentsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) { return new Error(error); }));
                                                        }
                                                        else {
                                                            promises2.push(_this.database.update(_this.tableReportsComments, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                        }
                                                        return Promise.all(promises2).then(function () {
                                                            _this.redirectUser({
                                                                report_id: data.data.report_id,
                                                                comment_id: data.data.id
                                                            });
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsCommentsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var commentsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsCommentsPush).then(function (reports_comments) {
                if (typeof reports_comments !== 'undefined') {
                    if (reports_comments.length) {
                        reports_comments.forEach(function (report_comment) {
                            if (report_comment.indexedDBMethod === '2-update') {
                                commentsData.push(report_comment);
                            }
                        });
                        if (commentsData.length) {
                            commentsData.forEach(function (comment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + comment.report_id + '/comments/' + comment.id, comment, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    var promises2 = [];
                                                    promises2.push(_this.database.update(_this.tableReportsComments, data.data)
                                                        .then(function () { return true; })
                                                        .catch(function (error) { return new Error(error); }));
                                                    promises2.push(_this.database.delete(_this.tableReportsCommentsPush, comment.entryId)
                                                        .then(function () { return true; })
                                                        .catch(function (error) { return new Error(error); }));
                                                    return Promise.all(promises2).then(function () {
                                                        resolve2();
                                                    });
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsCommentsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var commentsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsCommentsPush).then(function (reports_comments) {
                if (typeof reports_comments !== 'undefined') {
                    if (reports_comments.length) {
                        reports_comments.forEach(function (report_comment) {
                            if (report_comment.indexedDBMethod === '3-delete') {
                                commentsData.push(report_comment);
                            }
                        });
                        if (commentsData.length) {
                            commentsData.forEach(function (comment) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + comment.report_id + '/comments/' + comment.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsComments, comment.id)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            promises2.push(_this.database.delete(_this.tableReportsCommentsPush, comment.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsCommentsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsPush).then(function (reports_buildings) {
                if (typeof reports_buildings !== 'undefined') {
                    if (reports_buildings.length) {
                        reports_buildings.forEach(function (report_building) {
                            if (report_building.indexedDBMethod === '1-create') {
                                buildingsData.push(report_building);
                            }
                        });
                        if (buildingsData.length) {
                            buildingsData.forEach(function (building) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + building.report_id + '/buildings', building, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [], newId_5 = parseInt(data.data.id, 10);
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsPush, building.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        if (data.data.id !== building.id) {
                                                            promises2.push(_this.database.delete(_this.tableReportsBuildings, building.id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.add(_this.tableReportsBuildings, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsPush)
                                                                .then(function (reports_buildings2) {
                                                                var filtered = [];
                                                                reports_buildings2.forEach(function (report_building) {
                                                                    if (report_building.id === building.id) {
                                                                        report_building.id = newId_5;
                                                                        filtered.push(report_building);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                                                                .then(function (reports_buildings_reports) {
                                                                var filtered = [];
                                                                reports_buildings_reports.forEach(function (report_buildings_report) {
                                                                    if (report_buildings_report.building_id === building.id) {
                                                                        report_buildings_report.building_id = newId_5;
                                                                        filtered.push(report_buildings_report);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReports, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                                                                .then(function (reports_buildings_reports) {
                                                                var filtered = [];
                                                                reports_buildings_reports.forEach(function (report_buildings_report) {
                                                                    if (report_buildings_report.building_id === building.id) {
                                                                        report_buildings_report.building_id = newId_5;
                                                                        filtered.push(report_buildings_report);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReportsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos)
                                                                .then(function (reports_buildings_reports_photos) {
                                                                var filtered = [];
                                                                reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                                                                    if (report_building_report_photo.building_id === building.id) {
                                                                        report_building_report_photo.building_id = newId_5;
                                                                        filtered.push(report_building_report_photo);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReportsPhotos, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) { return new Error(error); }));
                                                        }
                                                        else {
                                                            promises2.push(_this.database.update(_this.tableReportsBuildings, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                        }
                                                        return Promise.all(promises2).then(function () {
                                                            _this.redirectUser({
                                                                report_id: data.data.report_id,
                                                                building_id: data.data.id,
                                                                old_building_id: building.id,
                                                                old_report_id: building.report_id
                                                            });
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsPush).then(function (reports_buildings) {
                if (typeof reports_buildings !== 'undefined') {
                    if (reports_buildings.length) {
                        reports_buildings.forEach(function (report_building) {
                            if (report_building.indexedDBMethod === '2-update') {
                                buildingsData.push(report_building);
                            }
                        });
                        if (buildingsData.length) {
                            buildingsData.forEach(function (building) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.post('reports/' + building.report_id + '/buildings/' + building.id, building, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsPush, building.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        promises2.push(_this.database.update(_this.tableReportsBuildings, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingsData = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsPush).then(function (reports_buildings) {
                if (typeof reports_buildings !== 'undefined') {
                    if (reports_buildings.length) {
                        reports_buildings.forEach(function (report_building) {
                            if (report_building.indexedDBMethod === '3-delete') {
                                buildingsData.push(report_building);
                            }
                        });
                        if (buildingsData.length) {
                            buildingsData.forEach(function (building) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + building.report_id + '/buildings/' + building.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsBuildings, building.id)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsPush, building.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPush).then(function (reports_buildings_reports) {
                if (typeof reports_buildings_reports !== 'undefined') {
                    if (reports_buildings_reports.length) {
                        reports_buildings_reports.forEach(function (report_building_report) {
                            if (report_building_report.indexedDBMethod === '1-create') {
                                buildingReport.push(report_building_report);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        delete report.photos;
                                        _this.apiService.post('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports', report, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [], newId_6 = parseInt(data.data.id, 10);
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPush, report.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        if (data.data.id !== report.id) {
                                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsReports, report.id)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.add(_this.tableReportsBuildingsReports, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                                                                .then(function (reports_building_reports2) {
                                                                var filtered = [];
                                                                reports_building_reports2.forEach(function (report_building_report) {
                                                                    if (report_building_report.id === report.id) {
                                                                        report_building_report.id = newId_6;
                                                                        filtered.push(report_building_report);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReportsPush, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) { return new Error(error); }));
                                                            promises2.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos)
                                                                .then(function (reports_buildings_reports_photos) {
                                                                var filtered = [];
                                                                reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                                                                    if (report_building_report_photo.building_report_id === report.id) {
                                                                        report_building_report_photo.building_report_id = newId_6;
                                                                        filtered.push(report_building_report_photo);
                                                                    }
                                                                });
                                                                if (filtered.length) {
                                                                    return _this.database.updateBulk(_this.tableReportsBuildingsReportsPhotos, filtered)
                                                                        .then(function () { return true; })
                                                                        .catch(function (error) { return new Error(error); });
                                                                }
                                                                return true;
                                                            })
                                                                .catch(function (error) { return new Error(error); }));
                                                        }
                                                        else {
                                                            promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                                .then(function () { return true; })
                                                                .catch(function (error) { return new Error(error); }));
                                                        }
                                                        return Promise.all(promises2).then(function () {
                                                            _this.redirectUser({
                                                                report_id: data.data.report_id,
                                                                building_id: data.data.building_id,
                                                                building_report_id: data.data.id,
                                                                old_report_id: report.report_id,
                                                                old_building_id: report.building_id,
                                                                old_building_report_id: report.id
                                                            });
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsUpdate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPush).then(function (reports_buildings_reports) {
                if (typeof reports_buildings_reports !== 'undefined') {
                    if (reports_buildings_reports.length) {
                        reports_buildings_reports.forEach(function (report_building_report) {
                            if (report_building_report.indexedDBMethod === '2-update') {
                                buildingReport.push(report_building_report);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        delete report.photos;
                                        _this.apiService.post('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.id, report, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPush, report.entryId)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPush).then(function (reports_buildings_reports) {
                if (typeof reports_buildings_reports !== 'undefined') {
                    if (reports_buildings_reports.length) {
                        reports_buildings_reports.forEach(function (report_building_report) {
                            if (report_building_report.indexedDBMethod === '3-delete') {
                                buildingReport.push(report_building_report);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.id, false).then(function () {
                                            var promises2 = [];
                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsReports, report.id)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPush, report.entryId)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsPhotosCreate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos).then(function (reports_buildings_reports_photos) {
                if (typeof reports_buildings_reports_photos !== 'undefined') {
                    if (reports_buildings_reports_photos.length) {
                        reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                            if (report_building_report_photo.indexedDBMethod === '1-create') {
                                buildingReport.push(report_building_report_photo);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        var photos_1 = {
                                            photos: []
                                        };
                                        report.photos.forEach(function (photo) {
                                            photos_1.photos.push(photo.file);
                                        });
                                        _this.apiService.post('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/photos', photos_1, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPhotos, report.id)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosCreate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsBuildingsReportsPhotosDelete = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var buildingReport = [], promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete start ...');
            }
            return _this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos).then(function (reports_buildings_reports_photos) {
                if (typeof reports_buildings_reports_photos !== 'undefined') {
                    if (reports_buildings_reports_photos.length) {
                        reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                            if (report_building_report_photo.indexedDBMethod === '3-delete') {
                                buildingReport.push(report_building_report_photo);
                            }
                        });
                        if (buildingReport.length) {
                            buildingReport.forEach(function (report) {
                                promises.push(new Promise(function (resolve2) {
                                    if (_this.apiService.isOnline) {
                                        _this.apiService.delete('reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id + '/delete-image/' + report.id, false).then(function (data) {
                                            if (typeof data !== 'undefined') {
                                                if (data.success === true) {
                                                    if (typeof data.data !== 'undefined') {
                                                        var promises2 = [];
                                                        promises2.push(_this.database.delete(_this.tableReportsBuildingsReportsPhotos, report.id)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        promises2.push(_this.database.update(_this.tableReportsBuildingsReports, data.data)
                                                            .then(function () { return true; })
                                                            .catch(function (error) { return new Error(error); }));
                                                        return Promise.all(promises2).then(function () {
                                                            resolve2();
                                                        });
                                                    }
                                                    else {
                                                        resolve2();
                                                    }
                                                }
                                                else {
                                                    resolve2();
                                                }
                                            }
                                            else {
                                                resolve2();
                                            }
                                        }).catch(function (error) { return new Error(error); });
                                    }
                                    else {
                                        resolve2();
                                    }
                                }));
                            });
                            return Promise.all(promises).then(function () {
                                if (_this.debug) {
                                    console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete done');
                                }
                                resolve();
                            });
                        }
                        else {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
                            }
                            resolve();
                        }
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsBuildingsReportsPhotosDelete nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.pushToServer_ReportsSendToClient = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient start ...');
            }
            return _this.database.getAllFast(_this.tableReportsSendToClient).then(function (reports_send_to_client) {
                if (typeof reports_send_to_client !== 'undefined') {
                    if (reports_send_to_client.length) {
                        reports_send_to_client.forEach(function (report) {
                            promises.push(new Promise(function (resolve2) {
                                if (_this.apiService.isOnline) {
                                    return _this.apiService.post('reports/' + report.report_id + '/send-to-email', report, false)
                                        .then(function () {
                                        return _this.database.delete(_this.tableReportsSendToClient, report.entryId)
                                            .then(function () { return resolve2(); })
                                            .catch(function (error) { return new Error(error); });
                                    })
                                        .catch(function (error) { return new Error(error); });
                                }
                                else {
                                    resolve2();
                                }
                            }));
                        });
                        return Promise.all(promises).then(function () {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient done');
                            }
                            resolve();
                        });
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_ReportsSendToClient nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.deleteReport = function (client_ids, report_ids) {
        var _this = this;
        if (client_ids === void 0) { client_ids = null; }
        if (report_ids === void 0) { report_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (client_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReports)
                    .then(function (reports) {
                    if (typeof reports !== 'undefined') {
                        if (reports.length) {
                            var filtered_4 = [];
                            reports.forEach(function (report) {
                                client_ids.forEach(function (client_id) {
                                    if (report.client_id === client_id.cid) {
                                        filtered_4.push(report.id);
                                    }
                                });
                            });
                            if (filtered_4.length) {
                                filtered_4 = __spread(new Set(filtered_4));
                                return _this.database.deleteBulk(_this.tableReports, filtered_4)
                                    .then(function () {
                                    var promises2 = [], rids = filtered_4.map(function (report) {
                                        return { rid: report.id };
                                    });
                                    promises2.push(_this.deleteReportAttachment(rids));
                                    promises2.push(_this.deleteReportComment(rids));
                                    promises2.push(_this.deleteReportSendToClient(client_ids));
                                    promises2.push(_this.deleteReportBuilding(rids));
                                    promises2.push(_this.deleteReportBuildingReport(rids));
                                    promises2.push(_this.deleteReportBuildingReportPhoto(rids));
                                    return Promise.all(promises2).then(function () { return true; });
                                })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsPush)
                    .then(function (reports) {
                    if (typeof reports !== 'undefined') {
                        if (reports.length) {
                            var filtered_5 = [];
                            reports.forEach(function (report) {
                                client_ids.forEach(function (client_id) {
                                    if (report.client_id === client_id.cid) {
                                        filtered_5.push(report.entryId);
                                    }
                                });
                            });
                            if (filtered_5.length) {
                                filtered_5 = __spread(new Set(filtered_5));
                                return _this.database.deleteBulk(_this.tableReportsPush, filtered_5)
                                    .then(function () {
                                    var promises2 = [], rids = filtered_5.map(function (report) {
                                        return { rid: report.id };
                                    });
                                    promises2.push(_this.deleteReportAttachment(rids));
                                    promises2.push(_this.deleteReportComment(rids));
                                    promises2.push(_this.deleteReportBuilding(rids));
                                    promises2.push(_this.deleteReportBuildingReport(rids));
                                    promises2.push(_this.deleteReportBuildingReportPhoto(rids));
                                    return Promise.all(promises2).then(function () { return true; });
                                })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReports, report_ids.map(function (ids) { return ids.rid; }))
                    .then(function () { return true; })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsPush)
                    .then(function (reports) {
                    if (typeof reports !== 'undefined') {
                        if (reports.length) {
                            var filtered_6 = [];
                            reports.forEach(function (report) {
                                report_ids.forEach(function (report_id) {
                                    if (report.id === report_id.rid) {
                                        filtered_6.push(report.entryId);
                                    }
                                });
                            });
                            if (filtered_6.length) {
                                filtered_6 = __spread(new Set(filtered_6));
                                return _this.database.deleteBulk(_this.tableReportsPush, filtered_6)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.deleteReportAttachment(report_ids));
                promises.push(_this.deleteReportComment(report_ids));
                promises.push(_this.deleteReportBuilding(report_ids));
                promises.push(_this.deleteReportBuildingReport(report_ids));
                promises.push(_this.deleteReportBuildingReportPhoto(report_ids));
                promises.push(_this.deleteReportSendToClient(null, report_ids));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportAttachment = function (report_ids, attachment_ids) {
        var _this = this;
        if (attachment_ids === void 0) { attachment_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (attachment_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsAttachments, attachment_ids.map(function (ids) { return ids.raid; }))
                    .then(function () { return true; })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_7 = [];
                            attachments.forEach(function (attachment) {
                                attachment_ids.forEach(function (attachment_id) {
                                    if (attachment.report_id === attachment_id.rid && attachment.id === attachment_id.raid) {
                                        filtered_7.push(attachment.entryId);
                                    }
                                });
                            });
                            if (filtered_7.length) {
                                filtered_7 = __spread(new Set(filtered_7));
                                return _this.database.deleteBulk(_this.tableReportsAttachmentsPush, filtered_7)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsAttachments)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_8 = [];
                            attachments.forEach(function (attachment) {
                                report_ids.forEach(function (report_id) {
                                    if (attachment.report_id === report_id.rid) {
                                        filtered_8.push(attachment.id);
                                    }
                                });
                            });
                            if (filtered_8.length) {
                                filtered_8 = __spread(new Set(filtered_8));
                                return _this.database.deleteBulk(_this.tableReportsAttachments, filtered_8)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsAttachmentsPush)
                    .then(function (attachments) {
                    if (typeof attachments !== 'undefined') {
                        if (attachments.length) {
                            var filtered_9 = [];
                            attachments.forEach(function (attachment) {
                                report_ids.forEach(function (report_id) {
                                    if (attachment.report_id === report_id.rid) {
                                        filtered_9.push(attachment.entryId);
                                    }
                                });
                            });
                            if (filtered_9.length) {
                                filtered_9 = __spread(new Set(filtered_9));
                                return _this.database.deleteBulk(_this.tableReportsAttachmentsPush, filtered_9)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportComment = function (report_ids, comment_ids) {
        var _this = this;
        if (comment_ids === void 0) { comment_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (comment_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsComments, comment_ids.map(function (ids) { return ids.rcid; }))
                    .then(function () { return true; })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsCommentsPush)
                    .then(function (comments) {
                    if (typeof comments !== 'undefined') {
                        if (comments.length) {
                            var filtered_10 = [];
                            comments.forEach(function (comment) {
                                comment_ids.forEach(function (comment_id) {
                                    if (comment.id === comment_id.rcid) {
                                        filtered_10.push(comment.entryId);
                                    }
                                });
                            });
                            if (filtered_10.length) {
                                filtered_10 = __spread(new Set(filtered_10));
                                return _this.database.deleteBulk(_this.tableReportsCommentsPush, filtered_10)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsComments)
                    .then(function (comments) {
                    if (typeof comments !== 'undefined') {
                        if (comments.length) {
                            var filtered_11 = [];
                            comments.forEach(function (comment) {
                                report_ids.forEach(function (report_id) {
                                    if (comment.report_id === report_id.rid) {
                                        filtered_11.push(comment.id);
                                    }
                                });
                            });
                            if (filtered_11.length) {
                                filtered_11 = __spread(new Set(filtered_11));
                                return _this.database.deleteBulk(_this.tableReportsComments, filtered_11)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsCommentsPush)
                    .then(function (comments) {
                    if (typeof comments !== 'undefined') {
                        if (comments.length) {
                            var filtered_12 = [];
                            comments.forEach(function (comment) {
                                report_ids.forEach(function (report_id) {
                                    if (comment.report_id === report_id.rid) {
                                        filtered_12.push(comment.entryId);
                                    }
                                });
                            });
                            if (filtered_12.length) {
                                filtered_12 = __spread(new Set(filtered_12));
                                return _this.database.deleteBulk(_this.tableReportsCommentsPush, filtered_12)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuilding = function (report_ids, building_ids) {
        var _this = this;
        if (building_ids === void 0) { building_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (building_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsBuildings, building_ids.map(function (ids) { return ids.rbid; }))
                    .then(function () { return true; })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsPush)
                    .then(function (report_buildings) {
                    if (typeof report_buildings !== 'undefined') {
                        if (report_buildings.length) {
                            var filtered_13 = [];
                            report_buildings.forEach(function (report_building) {
                                building_ids.forEach(function (building_id) {
                                    if (report_building.id === building_id.rbid) {
                                        filtered_13.push(report_building.entryId);
                                    }
                                });
                            });
                            if (filtered_13.length) {
                                filtered_13 = __spread(new Set(filtered_13));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsPush, filtered_13)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                }));
                promises.push(_this.deleteReportBuildingReport(null, building_ids));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildings)
                    .then(function (report_buildings) {
                    if (typeof report_buildings !== 'undefined') {
                        if (report_buildings.length) {
                            var filtered_14 = [];
                            report_buildings.forEach(function (report_building) {
                                report_ids.forEach(function (report_id) {
                                    if (report_building.report_id === report_id.rid) {
                                        filtered_14.push(report_building.id);
                                    }
                                });
                            });
                            if (filtered_14.length) {
                                filtered_14 = __spread(new Set(filtered_14));
                                return _this.database.deleteBulk(_this.tableReportsBuildings, filtered_14)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsPush)
                    .then(function (report_buildings) {
                    if (typeof report_buildings !== 'undefined') {
                        if (report_buildings.length) {
                            var filtered_15 = [];
                            report_buildings.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_15.push(building_report.entryId);
                                    }
                                });
                            });
                            if (filtered_15.length) {
                                filtered_15 = __spread(new Set(filtered_15));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsPush, filtered_15)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuildingReport = function (report_ids, building_ids, building_report_ids) {
        var _this = this;
        if (building_ids === void 0) { building_ids = null; }
        if (building_report_ids === void 0) { building_report_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (building_report_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsBuildingsReports, building_report_ids.map(function (ids) { return ids.rbrid; }))
                    .then(function () { return true; })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_16 = [];
                            building_reports.forEach(function (building_report) {
                                building_report_ids.forEach(function (building_report_id) {
                                    if (building_report.id === building_report_id.rbrid) {
                                        filtered_16.push(building_report.entryId);
                                    }
                                });
                            });
                            if (filtered_16.length) {
                                filtered_16 = __spread(new Set(filtered_16));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPush, filtered_16)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            if (building_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_17 = [];
                            building_reports.forEach(function (building_report) {
                                building_ids.forEach(function (building_id) {
                                    if (building_report.building_id === building_id.rbid) {
                                        filtered_17.push(building_report);
                                    }
                                });
                            });
                            if (filtered_17.length) {
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReports, filtered_17.map(function (ids) { return ids.id; }))
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_18 = [];
                            building_reports.forEach(function (building_report) {
                                building_ids.forEach(function (building_id) {
                                    if (building_report.building_id === building_id.rbid) {
                                        filtered_18.push(building_report);
                                    }
                                });
                            });
                            if (filtered_18.length) {
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPush, filtered_18.map(function (ids) { return ids.entryId; }))
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_19 = [];
                            building_reports.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_19.push(building_report.id);
                                    }
                                });
                            });
                            if (filtered_19.length) {
                                filtered_19 = __spread(new Set(filtered_19));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReports, filtered_19)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPush)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_20 = [];
                            building_reports.forEach(function (building_report) {
                                report_ids.forEach(function (report_id) {
                                    if (building_report.report_id === report_id.rid) {
                                        filtered_20.push(building_report.entryId);
                                    }
                                });
                            });
                            if (filtered_20.length) {
                                filtered_20 = __spread(new Set(filtered_20));
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPush, filtered_20)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportBuildingReportPhoto = function (report_ids, photo_ids) {
        var _this = this;
        if (photo_ids === void 0) { photo_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (photo_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReports)
                    .then(function (building_reports) {
                    if (typeof building_reports !== 'undefined') {
                        if (building_reports.length) {
                            var filtered_21 = [];
                            building_reports.forEach(function (building_report) {
                                photo_ids.forEach(function (photo_id) {
                                    if (building_report.id === photo_id.rbrid && building_report.building_id === photo_id.rbid && typeof building_report.photos !== 'undefined') {
                                        if (typeof building_report.photos.inspector !== 'undefined') {
                                            building_report.photos.inspector.forEach(function (photo, index) {
                                                if (parseInt(photo.id, 10) === photo_id.rbrpid) {
                                                    building_report.photos.inspector.splice(index, 1);
                                                }
                                            });
                                        }
                                        if (typeof building_report.photos.by_client !== 'undefined') {
                                            building_report.photos.inspector.forEach(function (photo, index) {
                                                if (parseInt(photo.id, 10) === photo_id.rbrpid) {
                                                    building_report.photos.by_client.splice(index, 1);
                                                }
                                            });
                                        }
                                        filtered_21.push(building_report);
                                    }
                                });
                            });
                            if (filtered_21.length) {
                                return _this.database.update(_this.tableReportsBuildingsReports, filtered_21)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                })
                    .catch(function (error) { return new Error(error); }));
            }
            if (report_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsBuildingsReportsPhotos)
                    .then(function (report_building_photos) {
                    if (typeof report_building_photos !== 'undefined') {
                        if (report_building_photos.length) {
                            var filtered_22 = [];
                            report_building_photos.forEach(function (report_building_photo) {
                                report_ids.forEach(function (report_id) {
                                    if (report_building_photo.report_id === report_id.rid) {
                                        filtered_22.push(report_building_photo.id);
                                    }
                                });
                            });
                            if (filtered_22.length) {
                                return _this.database.deleteBulk(_this.tableReportsBuildingsReportsPhotos, filtered_22)
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                })
                    .catch(function (error) { return new Error(error); }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteReportSendToClient = function (client_ids, report_ids) {
        var _this = this;
        if (client_ids === void 0) { client_ids = null; }
        if (report_ids === void 0) { report_ids = null; }
        return this.database.getAllFast(this.tableReportsSendToClient)
            .then(function (reports_send_to_client) {
            if (typeof reports_send_to_client !== 'undefined') {
                if (reports_send_to_client.length) {
                    var filtered_23 = [];
                    if (client_ids !== null) {
                        reports_send_to_client.forEach(function (report_send_to_client) {
                            client_ids.forEach(function (client_id) {
                                if (report_send_to_client.client_id === client_id.cid) {
                                    filtered_23.push(report_send_to_client.id);
                                }
                            });
                        });
                    }
                    if (report_ids !== null) {
                        reports_send_to_client.forEach(function (report_send_to_client) {
                            report_ids.forEach(function (report_id) {
                                if (report_send_to_client.report_id === report_id.rid) {
                                    filtered_23.push(report_send_to_client.id);
                                }
                            });
                        });
                    }
                    if (filtered_23.length) {
                        filtered_23 = __spread(new Set(filtered_23));
                        return _this.database.deleteBulk(_this.tableReportsSendToClient, filtered_23)
                            .then(function () { return true; })
                            .catch(function (error) { return new Error(error); });
                    }
                }
            }
            return true;
        })
            .catch(function (error) { return new Error(error); });
    };
    IndexedDBService.prototype.deleteAction = function (action_ids) {
        var _this = this;
        return this.database.getAllFast(this.tableReportsActions)
            .then(function (actions) {
            if (actions !== 'undefined') {
                if (actions.length) {
                    var filtered_24 = [];
                    actions.forEach(function (action) {
                        action_ids.forEach(function (action_id) {
                            if (action.id === action_id.waid) {
                                filtered_24.push(action);
                            }
                        });
                    });
                    if (filtered_24.length) {
                        return _this.database.deleteBulk(_this.tableReportsActions, filtered_24.map(function (ids) {
                            return [ids.id, ids.category_id, ids.subcategory_id];
                        }))
                            .then(function () { return true; })
                            .catch(function (error) { return new Error(error); });
                    }
                }
            }
            return true;
        })
            .catch(function (error) { return new Error(error); });
    };
    IndexedDBService.prototype.deleteCategory = function (category_ids) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.database.deleteBulk(_this.tableReportsCategories, category_ids.map(function (ids) { return ids.wcid; }))
                .then(function () { return true; })
                .catch(function (error) { return new Error(error); }));
            promises.push(_this.database.getAllFast(_this.tableReportsActions)
                .then(function (actions) {
                if (typeof actions !== 'undefined') {
                    if (actions.length) {
                        var filtered_25 = [];
                        actions.forEach(function (action) {
                            category_ids.forEach(function (category_id) {
                                if (action.category_id === category_id.wcid) {
                                    filtered_25.push(action);
                                }
                            });
                        });
                        if (filtered_25.length) {
                            return _this.database.deleteBulk(_this.tableReportsActions, filtered_25.map(function (ids) {
                                return [ids.id, ids.category_id, ids.subcategory_id];
                            }))
                                .then(function () { return true; })
                                .catch(function (error) { return new Error(error); });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            promises.push(_this.database.getAllFast(_this.tableReportsTypes)
                .then(function (types) {
                if (typeof types !== 'undefined') {
                    if (types.length) {
                        var filtered_26 = [];
                        types.forEach(function (type) {
                            category_ids.forEach(function (category_id) {
                                if (type.category_id === category_id.wcid) {
                                    filtered_26.push(type);
                                }
                            });
                        });
                        if (filtered_26.length) {
                            return _this.database.deleteBulk(_this.tableReportsTypes, filtered_26.map(function (ids) {
                                return [ids.id, ids.category_id, ids.subcategory_id];
                            }))
                                .then(function () { return true; })
                                .catch(function (error) { return new Error(error); });
                        }
                    }
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            promises.push(_this.deleteSubcategory(category_ids));
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteSubcategory = function (category_ids, subcategory_ids) {
        var _this = this;
        if (category_ids === void 0) { category_ids = null; }
        if (subcategory_ids === void 0) { subcategory_ids = null; }
        return new Promise(function (resolve) {
            var promises = [];
            if (subcategory_ids !== null) {
                promises.push(_this.database.deleteBulk(_this.tableReportsSubcategories, subcategory_ids.map(function (ids) {
                    return [ids.wsid, ids.wcid];
                }))
                    .then(function () { return true; })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsActions)
                    .then(function (actions) {
                    if (typeof actions !== 'undefined') {
                        if (actions.length) {
                            var filtered_27 = [];
                            actions.forEach(function (action) {
                                subcategory_ids.forEach(function (subcategory_id) {
                                    if (action.subcategory_id === subcategory_id.wsid) {
                                        filtered_27.push(action);
                                    }
                                });
                            });
                            if (filtered_27.length) {
                                return _this.database.deleteBulk(_this.tableReportsActions, filtered_27.map(function (ids) {
                                    return [ids.id, ids.category_id, ids.subcategory_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsTypes)
                    .then(function (types) {
                    if (typeof types !== 'undefined') {
                        if (types.length) {
                            var filtered_28 = [];
                            types.forEach(function (type) {
                                subcategory_ids.forEach(function (subcategory_id) {
                                    if (type.subcategory_id === subcategory_id.wsid) {
                                        filtered_28.push(type);
                                    }
                                });
                            });
                            if (filtered_28.length) {
                                return _this.database.deleteBulk(_this.tableReportsTypes, filtered_28.map(function (ids) {
                                    return [ids.id, ids.category_id, ids.subcategory_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            if (category_ids !== null) {
                promises.push(_this.database.getAllFast(_this.tableReportsSubcategories)
                    .then(function (subcategories) {
                    if (typeof subcategories !== 'undefined') {
                        if (subcategories.length) {
                            var filtered_29 = [];
                            subcategories.forEach(function (subcategory) {
                                category_ids.forEach(function (category_id) {
                                    if (subcategory.category_id === category_id.wcid) {
                                        filtered_29.push(subcategory);
                                    }
                                });
                            });
                            if (filtered_29.length) {
                                return _this.database.deleteBulk(_this.tableReportsSubcategories, filtered_29.map(function (ids) {
                                    return [ids.id, ids.category_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsActions)
                    .then(function (actions) {
                    if (typeof actions !== 'undefined') {
                        if (actions.length) {
                            var filtered_30 = [];
                            actions.forEach(function (action) {
                                category_ids.forEach(function (category_id) {
                                    if (action.category_id === category_id.wcid) {
                                        filtered_30.push(action);
                                    }
                                });
                            });
                            if (filtered_30.length) {
                                return _this.database.deleteBulk(_this.tableReportsActions, filtered_30.map(function (ids) {
                                    return [ids.id, ids.category_id, ids.subcategory_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
                promises.push(_this.database.getAllFast(_this.tableReportsTypes)
                    .then(function (types) {
                    if (typeof types !== 'undefined') {
                        if (types.length) {
                            var filtered_31 = [];
                            types.forEach(function (type) {
                                category_ids.forEach(function (category_id) {
                                    if (type.category_id === category_id.wcid) {
                                        filtered_31.push(type);
                                    }
                                });
                            });
                            if (filtered_31.length) {
                                return _this.database.deleteBulk(_this.tableReportsTypes, filtered_31.map(function (ids) {
                                    return [ids.id, ids.category_id, ids.subcategory_id];
                                }))
                                    .then(function () { return true; })
                                    .catch(function (error) { return new Error(error); });
                            }
                        }
                    }
                    return true;
                })
                    .catch(function (error) { return new Error(error); }));
            }
            return Promise.all(promises).then(function () {
                resolve();
            });
        });
    };
    IndexedDBService.prototype.deleteType = function (type_ids) {
        var _this = this;
        return this.database.getAllFast(this.tableReportsTypes)
            .then(function (types) {
            if (typeof types !== 'undefined') {
                if (types.length) {
                    var filtered_32 = [];
                    types.forEach(function (type) {
                        type_ids.forEach(function (type_id) {
                            if (type.id === type_id.wtid) {
                                filtered_32.push(type);
                            }
                        });
                    });
                    if (filtered_32.length) {
                        return _this.database.deleteBulk(_this.tableReportsTypes, filtered_32.map(function (ids) {
                            return [ids.id, ids.category_id, ids.subcategory_id];
                        }))
                            .then(function () { return true; })
                            .catch(function (error) { return new Error(error); });
                    }
                }
            }
            return true;
        })
            .catch(function (error) { return new Error(error); });
    };
    IndexedDBService.prototype.deleteCategorySubcategoryAction = function (action_ids) {
        return this.database.deleteBulk(this.tableReportsActions, action_ids.map(function (ids) {
            return [ids.waid, ids.wcid, ids.wsid];
        }))
            .then(function () { return true; })
            .catch(function (error) { return new Error(error); });
    };
    IndexedDBService.prototype.deleteCategorySubcategoryType = function (type_ids) {
        return this.database.deleteBulk(this.tableReportsTypes, type_ids.map(function (ids) {
            return [ids.wtid, ids.wcid, ids.wsid];
        }))
            .then(function () { return true; })
            .catch(function (error) { return new Error(error); });
    };
    IndexedDBService.prototype.redirectUser = function (dataParams) {
        var _this = this;
        var params = this.previousUrlService.routeData;
        setTimeout(function () {
            if (typeof params !== 'undefined' && typeof dataParams !== 'undefined') {
                if (params !== null && dataParams !== null) {
                    if (typeof params['report_id'] !== 'undefined' && typeof dataParams['report_id'] !== 'undefined') {
                        if (typeof params['building_id'] !== 'undefined' && typeof dataParams['building_id'] !== 'undefined') {
                            if (typeof params['building_report_id'] !== 'undefined' && typeof dataParams['building_report_id'] !== 'undefined' && typeof dataParams['old_building_id'] !== 'undefined') {
                                if (parseInt(dataParams['old_building_report_id'], 10) === parseInt(params['building_id'], 10)) {
                                    _this.router.navigate(['/reports/' + dataParams['report_id'] + '/buildings/' + dataParams['building_id'] + '/reports/' + dataParams['building_report_id'] + '/edit']).then();
                                    return true;
                                }
                            }
                            if (typeof dataParams['old_building_id'] !== 'undefined') {
                                if (parseInt(dataParams['old_building_id'], 10) === parseInt(params['building_id'], 10)) {
                                    _this.router.navigate(['/reports/' + dataParams['report_id'] + '/buildings/' + dataParams['building_id'] + '/reports']).then();
                                    return true;
                                }
                            }
                        }
                        if (typeof dataParams['report_id'] !== 'undefined' && typeof dataParams['old_report_id'] !== 'undefined') {
                            if (parseInt(dataParams['old_report_id'], 10) === parseInt(params['report_id'], 10)) {
                                _this.router.navigate(['/reports/' + '/' + dataParams['report_id'] + '/view']).then();
                                return true;
                            }
                        }
                    }
                }
            }
        }, 1500);
    };
    /* KVVM Reports */
    IndexedDBService.prototype.pushToServer_KVVMReportsUpdates = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [], start = new Date().getTime();
            if (_this.debug) {
                console.log((new Date().getTime() - start), 'pushToServer_KVVMReportsUpdate start ...');
            }
            return _this.database.getAllFast(_this.tableKvvmReportsBuildingsReportsSolved).then(function (reports) {
                if (typeof reports !== 'undefined') {
                    if (reports.length) {
                        reports.forEach(function (report) {
                            promises.push(new Promise(function (resolve2) {
                                if (_this.apiService.isOnline) {
                                    _this.apiService.post('kvvm-reports/' + report.report_id + '/buildings/' + report.building_id + '/reports/' + report.building_report_id, report, false).then(function (data) {
                                        if (data.success === true) {
                                            var promises2 = [];
                                            promises2.push(_this.database.update(_this.tableKvvmReportsBuildingsReports, data.data)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            promises2.push(_this.database.delete(_this.tableKvvmReportsBuildingsReportsSolved, report.id)
                                                .then(function () { return true; })
                                                .catch(function (error) { return new Error(error); }));
                                            return Promise.all(promises2).then(function () {
                                                resolve2();
                                            });
                                        }
                                        else {
                                            resolve2();
                                        }
                                    }).catch(function (error) { return new Error(error); });
                                }
                                else {
                                    resolve2();
                                }
                            }));
                        });
                        return Promise.all(promises).then(function () {
                            if (_this.debug) {
                                console.log((new Date().getTime() - start), 'pushToServer_KVVMReportsUpdate done');
                            }
                            resolve();
                        });
                    }
                    else {
                        if (_this.debug) {
                            console.log((new Date().getTime() - start), 'pushToServer_KVVMReportsUpdate nothing to do');
                        }
                        resolve();
                    }
                }
                else {
                    if (_this.debug) {
                        console.log((new Date().getTime() - start), 'pushToServer_KVVMReportsUpdate nothing to do');
                    }
                    resolve();
                }
            }).catch(function (error) { return new Error(error); });
        });
    };
    IndexedDBService.prototype.syncKVVMReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_12 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableKvvmReports).then(function (user_device_date) {
                    _this.apiService.get('kvvm-reports/sync/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('kvvm-reports/sync', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_12 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_12), 'syncKVVMReports start ...');
                                                                }
                                                                promises_12.push(_this.database.updateBulk(_this.tableKvvmReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_12.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableKvvmReports,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_12).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_12), 'syncKVVMReports done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncKVVMReportsAttachments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_13 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableKvvmReportsAttachments).then(function (user_device_date) {
                    _this.apiService.get('kvvm-reports/sync/attachments/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('kvvm-reports/sync/attachments', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_13 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_13), 'syncKVVMReportsAttachments start ...');
                                                                }
                                                                promises_13.push(_this.database.updateBulk(_this.tableKvvmReportsAttachments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_13.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableKvvmReportsAttachments,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_13).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_13), 'syncKVVMReportsAttachments done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncKVVMReportsComments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_14 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableKvvmReportsComments).then(function (user_device_date) {
                    _this.apiService.get('kvvm-reports/sync/comments/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('kvvm-reports/sync/comments', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_14 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_14), 'syncKVVMReportsComments start ...');
                                                                }
                                                                promises_14.push(_this.database.updateBulk(_this.tableKvvmReportsComments, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_14.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableKvvmReportsComments,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_14).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_14), 'syncKVVMReportsComments done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncKVVMReportsBuildings = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_15 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableKvvmReportsBuildings).then(function (user_device_date) {
                    _this.apiService.get('kvvm-reports/sync/buildings/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('kvvm-reports/sync/buildings', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_15 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_15), 'syncKVVMReportsBuildings start ...');
                                                                }
                                                                promises_15.push(_this.database.updateBulk(_this.tableKvvmReportsBuildings, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_15.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableKvvmReportsBuildings,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_15).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_15), 'syncKVVMReportsBuildings done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    IndexedDBService.prototype.syncKVVMReportsBuildingsReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                var promises_16 = [];
                _this.database.getByKey(_this.tableDataSync, _this.tableKvvmReportsBuildingsReports).then(function (user_device_date) {
                    _this.apiService.get('kvvm-reports/sync/buildings/reports/last-update-date', null, false).then(function (server_date) {
                        if (typeof server_date !== 'undefined') {
                            if (server_date.success === true) {
                                if (typeof server_date.data !== 'undefined') {
                                    if (typeof server_date.data.updated !== 'undefined') {
                                        var update = false, last_update_date = null;
                                        if (typeof user_device_date !== 'undefined') {
                                            if (typeof user_device_date.updated !== 'undefined') {
                                                if (user_device_date.updated < server_date.data.updated) {
                                                    last_update_date = user_device_date.updated;
                                                    update = true;
                                                }
                                            }
                                            else {
                                                update = true;
                                            }
                                        }
                                        else {
                                            update = true;
                                        }
                                        if (update === true) {
                                            _this.apiService.get('kvvm-reports/sync/buildings/reports', {
                                                start: 0,
                                                length: -1,
                                                last_update_date: last_update_date
                                            }, false).then(function (data) {
                                                if (typeof data !== 'undefined') {
                                                    if (typeof data.success !== 'undefined') {
                                                        if (data.success === true) {
                                                            if (typeof data.data !== 'undefined') {
                                                                var start_16 = new Date().getTime();
                                                                if (_this.debug) {
                                                                    console.log((new Date().getTime() - start_16), 'syncKVVMReportsBuildingsReports start ...');
                                                                }
                                                                promises_16.push(_this.database.updateBulk(_this.tableKvvmReportsBuildingsReports, data.data)
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                promises_16.push(_this.database.update(_this.tableDataSync, {
                                                                    name: _this.tableKvvmReportsBuildingsReports,
                                                                    updated: server_date.data.updated
                                                                })
                                                                    .then(function () { return true; })
                                                                    .catch(function (error) { return new Error(error); }));
                                                                return Promise.all(promises_16).then(function () {
                                                                    if (_this.debug) {
                                                                        console.log((new Date().getTime() - start_16), 'syncKVVMReportsBuildingsReports done');
                                                                    }
                                                                    resolve();
                                                                });
                                                            }
                                                            else {
                                                                resolve();
                                                            }
                                                        }
                                                        else {
                                                            resolve();
                                                        }
                                                    }
                                                    else {
                                                        resolve();
                                                    }
                                                }
                                                else {
                                                    resolve();
                                                }
                                            }).catch(function (error) { return new Error(error); });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }
                                    else {
                                        resolve();
                                    }
                                }
                                else {
                                    resolve();
                                }
                            }
                            else {
                                resolve();
                            }
                        }
                        else {
                            resolve();
                        }
                    }).catch(function (error) { return new Error(error); });
                }).catch(function (error) { return new Error(error); });
            }
            else {
                resolve();
            }
        });
    };
    return IndexedDBService;
}());
export { IndexedDBService };
