<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">Bekijk rapportage</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<form class="with-action-buttons" [formGroup]="reportsEditForm" (ngSubmit)="reportsService.updateReport(report_id, reportsEditForm.value)">
		<div class="divider"><span>Rapportage gegevens</span></div>
		<mat-form-field>
			<input matInput title="" type="text" formControlName="report_nr" required placeholder="Naam inspectie">
		</mat-form-field>
		<mat-form-field>
			<textarea matInput title="" formControlName="client_description" rows="8" placeholder="Opmerkingen"></textarea>
		</mat-form-field>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!reportsEditForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
