<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">Bekijk rapportage</button>
<form class="with-action-buttons" [formGroup]="reportCommentCreateForm" (ngSubmit)="reportsService.createComment(report_id, reportCommentCreateForm.value)">
	<mat-form-field>
		<textarea title="" matInput placeholder="Opmerkingen" required formControlName="comment" rows="8"></textarea>
		<mat-error *ngIf="formsService.getFieldError('comment')">{{formsService.getFieldError('comment')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!reportCommentCreateForm.valid">OPSLAAN</button>
	</div>
</form>
