<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/buildings']">Bekijk alle gebouwen</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">Bekijk rapportage</button>
<form class="with-action-buttons" [formGroup]="reportsBuildingCreateForm" (ngSubmit)="reportsService.createBuilding(report_id, reportsBuildingCreateForm.value)">
	<mat-form-field>
		<input title="" matInput placeholder="Naam gebouw" type="text" required formControlName="name">
		<mat-error *ngIf="formsService.getFieldError('name')">{{formsService.getFieldError('name')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<textarea title="" matInput placeholder="Bijzonderheden" formControlName="description" rows="8"></textarea>
		<mat-error *ngIf="formsService.getFieldError('description')">{{formsService.getFieldError('description')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!reportsBuildingCreateForm.valid">OPSLAAN</button>
	</div>
</form>
