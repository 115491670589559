<button mat-button color="primary-outline" [routerLink]="['/reports']">Bekijk alle rapportages</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<button class="inline-edit-button" mat-icon-button [routerLink]="['/reports/' + report.id + '/edit']">
		<mat-icon>edit</mat-icon>
	</button>
	<dl *ngIf="report">
		<dt>Rapportage nummer</dt>
		<dd>{{ report.report_nr }}</dd>
		<dt>Datum aangemaakt</dt>
		<dd *ngIf="report.created !== null">{{ report.created | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="report.created === null">-</dd>
		<dt>Datum aangepast</dt>
		<dd *ngIf="report.modified !== null">{{ report.modified | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="report.modified === null">-</dd>
		<dt>Kleurcode</dt>
		<dd><div class="tag" [style.background]="report.color"></div></dd>
		<dt>Beschrijving</dt>
		<dd>{{report.client_description}}</dd>
	</dl>
	<div class="divider"><span>Gebouwen</span></div>
	<app-reports-buildings-list [smallWidget]="true" style="margin:-10px 0 0 0;"></app-reports-buildings-list>
	<div class="divider"><span>Download rapportage</span></div>
	<div class="buttons-in-line" fxLayout="row">
		<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadPDFReport(report.id, 'pdf')">PDF</button>
		<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadXLSReport(report.id, 'xlsx')">XLS</button>
		<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">PDF</button>
		<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">XLS</button>
	</div>
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report.id + '/send-to-email']">Verstuur naar email</button>
	<app-reports-comments-list [smallWidget]="true" style="margin:20px 0 0 0;"></app-reports-comments-list>
	<app-reports-attachments-list [smallWidget]="true" style="margin:20px 0 0 0;"></app-reports-attachments-list>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
