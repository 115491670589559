<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">Bekijk rapportage</button>
<form class="with-action-buttons" [formGroup]="reportAttachmentsCreateForm" (ngSubmit)="reportsService.createAttachment(report_id, reportAttachmentsCreateForm.value)">
	<mat-form-field>
		<ngx-mat-file-input formControlName="filename" placeholder="Bestand" required #filenameInput>
			<button mat-icon-button *ngIf="!filenameInput.empty" (click)="filenameInput.clear($event)">
				<mat-icon>clear</mat-icon>
			</button>
		</ngx-mat-file-input>
		<mat-error *ngIf="formsService.getFieldError('filename')">{{formsService.getFieldError('filename')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input title="" matInput placeholder="Titel" type="text" required formControlName="title">
		<mat-error *ngIf="formsService.getFieldError('title')">{{formsService.getFieldError('title')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<textarea title="" matInput placeholder="Omschrijving" formControlName="description" rows="8"></textarea>
		<mat-error *ngIf="formsService.getFieldError('description')">{{formsService.getFieldError('description')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!reportAttachmentsCreateForm.valid">OPSLAAN</button>
	</div>
</form>
