<ng-container *ngIf="publicClass">
	<button mat-button color="primary-outline" [routerLink]="[(publicClass ? '/public' : '') + '/knowledge-base']">
		<mat-icon>arrow_back</mat-icon>
		Back to knowledge base
	</button>
</ng-container>
<div *ngIf="group" class="main-container">
	<h2>{{ group.title }}</h2>
	<div>{{ group.content }}</div>
	<ng-container *ngIf="group.articles">
		<mat-list>
			<mat-list-item *ngFor="let article of group.articles" [routerLink]="[(publicClass ? '/public' : '') + '/knowledge-base/' + group.url + '/' + article.url]">
				<h4 mat-line>{{ article.title }}</h4>
				<p mat-line>{{ article.description }}</p>
				<mat-icon>chevron_right</mat-icon>
			</mat-list-item>
		</mat-list>
	</ng-container>
</div>
<div class="support-bottom">
	<h3>Get in touch</h3>
	<a class="support-icon" href="tel:0365490468">
		<mat-icon>phone</mat-icon>
		<p>036 549 04 68</p>
	</a>
	<a class="support-icon" href="mailto:app-support@kvvm.nl">
		<mat-icon>email</mat-icon>
		<p>app-support@kvvm.nl</p>
	</a>
</div>
