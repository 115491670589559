/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar-bottom.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "@angular/flex-layout/flex";
import * as i13 from "@angular/flex-layout/core";
import * as i14 from "./toolbar-bottom.component";
import * as i15 from "../../_services/previous-url.service";
var styles_ToolbarBottomComponent = [i0.styles];
var RenderType_ToolbarBottomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarBottomComponent, data: {} });
export { RenderType_ToolbarBottomComponent as RenderType_ToolbarBottomComponent };
export function View_ToolbarBottomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-toolbar", [["class", "bottom mat-toolbar"], ["color", "primary"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "button", [["class", "sidenavToggleButton"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousPageService.goToPreviousUrl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back"])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(9, 671744, null, 0, i12.DefaultFlexDirective, [i1.ElementRef, i13.StyleUtils, i13.LAYOUT_CONFIG, i12.FlexStyleBuilder, i13.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 4, "button", [["class", "sidenavToggleButton right"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousPageService.goTo("/") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "mat-icon", [["class", "avatar mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(13, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["home"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); _ck(_v, 6, 0); var currVal_7 = ""; _ck(_v, 9, 0, currVal_7); _ck(_v, 13, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 4).disabled || null); var currVal_4 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 6).inline; var currVal_6 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 11).disabled || null); var currVal_9 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_8, currVal_9); var currVal_10 = i1.ɵnov(_v, 13).inline; var currVal_11 = (((i1.ɵnov(_v, 13).color !== "primary") && (i1.ɵnov(_v, 13).color !== "accent")) && (i1.ɵnov(_v, 13).color !== "warn")); _ck(_v, 12, 0, currVal_10, currVal_11); }); }
export function View_ToolbarBottomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toolbar-bottom", [], null, null, null, View_ToolbarBottomComponent_0, RenderType_ToolbarBottomComponent)), i1.ɵdid(1, 49152, null, 0, i14.ToolbarBottomComponent, [i15.PreviousUrlService], null, null)], null, null); }
var ToolbarBottomComponentNgFactory = i1.ɵccf("app-toolbar-bottom", i14.ToolbarBottomComponent, View_ToolbarBottomComponent_Host_0, {}, {}, []);
export { ToolbarBottomComponentNgFactory as ToolbarBottomComponentNgFactory };
